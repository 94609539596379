import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from "react-router-dom"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useSelector } from 'react-redux';
import { currencyFormat } from '../../../helpers/numericFields';
import { Card, Col, Row } from 'react-bootstrap';


export const FilesTable = () => {
  const { customerPayments } = useSelector((state) => state.individualFiles);
  const columns = [
    {
      name: "Expediente",
      width: "85px",
      cell: row => <span className="text-center w-100">
        <OverlayTrigger placement="top" overlay={<Tooltip >Ver Expediente</Tooltip>}>
          <Link to={`/panel/expedienteIndividual?id=${row.id}`}
            className="btn btn-primary btn-sm rounded-11 me-2" ><span className="fs-15 fw-semibold text-center">{row.id}</span></Link>
        </OverlayTrigger>
      </span>
    },
    {
      name: "Fecha",
      selector: row => [row.requestDate],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.requestDate}</span>
    },
    {
      name: "Estatus",
      selector: row => [row.status],
      sortable: true,
      cell: row => <span className={`text-${row.status === "Saldo" ? "yellow" : "green"} fs-15 fw-semibold text-center w-100`}>{row.status}</span>
    },
    {
      name: "Cliente",
      selector: row => [row.customer],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.customer}</span>
    },
    {
      name: "Telefono",
      selector: row => [row.telephone],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.telephone}</span>
    },
    {
      name: "Movil",
      selector: row => [row.cellphone],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.cellphone}</span>
    },
    {
      name: "Total",
      sortable: false,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{currencyFormat(row.publicPrice)}</span>
    },
    {
      name: "Pagos",
      sortable: false,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{currencyFormat(row.paid)}</span>
    },
    {
      name: "Saldo",
      sortable: false,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{currencyFormat(row.paid - row.publicPrice)}</span>
    },
  ]
  const tableDatas = {
    columns,
    data: customerPayments.files,
  };
  return (
    <Row>
      <Col sm={12} className="col-12">
        <Card>
          <Card.Header>
            <h3 className="card-title mb-0">Expedientes</h3>
          </Card.Header>
          <Card.Body>
            <div className="dataTable">
              <div className="table-responsive">
                <DataTableExtensions {...tableDatas} >
                  <DataTable
                    columns={columns}
                    data={customerPayments.files}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    striped={true}
                    center={true}
                    persistTableHead
                    pagination
                    highlightOnHover />
                </DataTableExtensions>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}