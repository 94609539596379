import { useState } from 'react';
import { Row } from 'react-bootstrap'
import { currencyFormat } from '../../helpers/numericFields'
import styles from './ServiceDetails.module.css'
import { EditService } from './EditService';

export const ServiceDetails = ({ service, index }) => {
  const [editService, setEditService] = useState(false);

  return (
    <>
      <Row className={styles.container}>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Servicio:</div>
        <div className={`col-lg-4 col-md-4 ${styles.edit}`} onClick={() => setEditService(true)}>{service.service}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Mayorista:</div>
        <div className="col-lg-4 col-md-4">{service.wholesaler}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Inicio:</div>
        <div className="col-lg-4 col-md-4">{service.startDate}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Fin:</div>
        <div className="col-lg-4 col-md-4">{service.endDate}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Detalles:</div>
        <div className="col-lg-10 col-md-10">{service.details}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Notas:</div>
        <div className="col-lg-10 col-md-10">{service.notes}</div>
        <div style={{ height: 20 }}></div>
        <div className="col-lg-7 col-md-7"></div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Tarifa Publica:</div>
        <div className={`col-lg-3 col-md-3`}>{service.currency} {currencyFormat(service.rate)}</div>
        <div className={`col-lg-7 col-md-7`}></div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Tarifa Neta:</div>
        <div className={`col-lg-3 col-md-3`}>{service.currency} {currencyFormat(service.netPrice)}</div>
        <div className="col-lg-7 col-md-7"></div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle} ${styles.subTotal}`}>Sub Total:</div>
        <div className={`col-lg-3 col-md-3 ${styles.subTotal}`}>{service.currency} {currencyFormat(service.rate)}</div>
        <div style={{ height: 10 }}></div>
        <div className={`col-12 ${styles.line}`}></div>
      </Row>
      <EditService service={service} isOpen={editService} setIsOpen={setEditService} />
    </>
  )
}
