import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import esLocale from '@fullcalendar/core/locales/es'
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumb, Card, Row, Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { SearchForm } from "./SearchForm";
import { AddEvent } from "./AddEvent";
import { EditEvent } from "./EditEvent";
import { getCalendarEvents } from "../../../store/slices/catalogue/thunks";
import { setCalendarEvents } from "../../../store/slices/catalogue/catalogueSlice";


export const CalendarScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { calendarEvents } = useSelector((state) => state.catalogue);
  const [isOpen, setIsOpen] = useState(false);
  const [addEvent, setAddEvent] = useState(false);
  const [edit, setEdit] = useState(false);
  const [eventSelected, setEventSelected] = useState({});

  useEffect(() => {
    dispatch(getCalendarEvents());

    return () => {
      dispatch(setCalendarEvents({ isLoading: true, events: [] }));
    }
  }, [])

  function renderEventContent(eventInfo) {
    const event = eventInfo.event.title.split("\n");
    return (
      <>

        <b>{eventInfo.timeText}</b>
        <i>{event[0]}</i>
        <br />
        <i>{event[1]}</i>
      </>
    );
  }
  const handleEventClick = (clickInfo) => {    

    let event = calendarEvents.events.find(x => x.id == clickInfo.event.id);
    if (event) {

      if (event.tipo == 'grupo') {
        navigate("/panel/expedienteGrupo?id=" + clickInfo.event.id);
      }

      if (event.tipo == 'evento') {
        setEventSelected(event);
        setEdit(true);
      }
    }
  };
  const handleEvents = (events) => { };

  const handleDateSelect = (selectInfo) => {
    // let title = prompt("Please enter a new title for your event");
    // let calendarApi = selectInfo.view.calendar;

    // calendarApi.unselect();

    // if (title) {
    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay,
    //   });
    // }
  };
  return (
    <div>
      <div style={{ marginTop: 20, marginBottom: 20, display: "flex", justifyContent: "space-between" }} >
        <button className="btn btn-primary btn-icon text-white" onClick={() => setAddEvent(true)}>Agregar Evento</button>
        <button className="btn btn-primary btn-icon text-white" onClick={() => setIsOpen(true)}>Buscar Espacio Disponible</button>
        <SearchForm isOpen={isOpen} setIsOpen={setIsOpen} />
        <AddEvent isOpen={addEvent} setIsOpen={setAddEvent} />
      </div>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <h3 className="card-title">
                Eventos Agendados
              </h3>
            </Card.Header>
            <Card.Body>
              <Row>
                {
                  !calendarEvents.isLoading &&
                  <Col md={12} >
                    <div className="fullclndr">
                      <FullCalendar
                        locale={esLocale}
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        initialView="dayGridMonth"
                        editable={false}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        weekends={true}
                        initialEvents={calendarEvents.events}
                        select={handleDateSelect}
                        eventContent={renderEventContent}
                        eventClick={handleEventClick}
                        eventsSet={handleEvents}
                      />
                    </div>
                  </Col>
                }
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <EditEvent event={eventSelected} isOpen={edit} setIsOpen={setEdit} />
    </div>
  );
}
