import { useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useDispatch, useSelector } from 'react-redux';
import { EditHotel } from "./EditHotel";
import styles from './HotelTable.module.css';
import { HotelImages } from "./HotelImages";
import { getHotelImagesList } from "../../../store/slices/catalogue/thunks";

export const HotelTable = () => {
  const dispatch = useDispatch();

  const { hotelsList } = useSelector((state) => state.catalogue);
  const [editHotel, setEditHotel] = useState(false)
  const [hotelSelected, setHotelSelected] = useState({});
  const [viewImages, setViewImages] = useState(false)

  const editHotel2 = (hotel) => {
    setEditHotel(true);
    setHotelSelected(hotel)
  }

  const viewImages2 = (hotel) => {
    dispatch(getHotelImagesList(hotel.id))
    setViewImages(true);    
    setHotelSelected(hotel);
  }

  const columns = [
    {
      name: "Nombre",
      selector: row => [row.name],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold">{row.name}</span>
    },
    {
      name: "Codigo",
      selector: row => [row.code],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold">{row.code}</span>
    },
    {
      name: "Direccion",
      selector: row => [row.address],
      sortable: true,
      width: "200px",
      cell: row => <span className="text-muted fs-15 fw-semibold">{row.address}</span>
    },
    {
      name: "Ciudad",
      selector: row => [row.city],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold">{row.city}</span>
    },
    {
      name: "Estado",
      selector: row => [row.state],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold">{row.state}</span>
    },
    {
      name: "Pais",
      selector: row => [row.country],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold">{row.country}</span>
    },
    {
      name: "Telefono",
      selector: row => [row.telephone],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold">{row.telephone}</span>
    },
    {
      name: "Correo Electronico",
      selector: row => [row.email],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold">{row.email}</span>
    },
    {
      name: "Notas",
      selector: row => [row.notes],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold">{row.notes}</span>
    },
    {
      name: "Imagenes",
      width: "85px",
      cell: row => <span className="text-center w-100">
        <i className={`fa fa-image ${styles.images}`} onClick={() => viewImages2(row)}></i>
      </span>
    },
    {
      name: "Estatus",
      selector: row => [row.status],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold">{row.status === 1 ? "Activo" : "Inactivo"}</span>
    },
    {
      name: "Editar",
      width: "85px",
      cell: row => <span className="text-center w-100">
        <i className={`fe fe-edit .hover`} role='button' onClick={() => editHotel2(row)}></i>
      </span>
    },
  ]
  const tableDatas = {
    columns,
    data: hotelsList,
  };
  return (
    <>
      <DataTableExtensions {...tableDatas} >
        <DataTable
          columns={columns}
          data={hotelsList}
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          striped={true}
          center={true}
          persistTableHead
          pagination
          highlightOnHover />
      </DataTableExtensions>
      <EditHotel hotel={hotelSelected} isOpen={editHotel} setIsOpen={setEditHotel} />
      <HotelImages hotel={hotelSelected} isOpen={viewImages} setIsOpen={setViewImages} />
    </>
  )
}