import React from "react";
import {
  Card,
  Row,
  Button,
} from "react-bootstrap";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../../ui/controls/MySelect";
import { getReminderList } from "../../../store/slices/catalogue/thunks";
import { MyDatePicker } from "../../ui/controls/MyDatePicker";

export const FormSearchReminder = () => {
  const dispatch = useDispatch();
  const { usersList } = useSelector((state) => state.general);

  return (
    <div>

      <Row>
        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
          <Card>
            <Card.Header>
              <h4 className="card-title">Buscar Recordatorios</h4>
            </Card.Header>
            <Card.Body>
              <Formik
                initialValues={{
                  user: {value:0, label:"Todos"},
                  date: undefined,
                }}
                onSubmit={(({ user, date },
                  { resetForm, setErrors }) => {
                  dispatch(getReminderList(user.value,
                    date ? date.year + "-" + (date.monthIndex + 1) + "-" + date.day : undefined));

                })}
                validationSchema={
                  Yup.object({
                  })
                }
              >

                {
                  ({ setFieldValue }) => (
                    <Form>
                      <Row>
                        <div className="col-lg-6 col-md-12">
                          <div className=" row mb-6">
                            <MySelect label='Por creador:' name="user" options={[{ value: 0, label: "Todos" }, ...usersList]} mode='horizontal'
                              singleSelect="true"
                              onChange={e => {
                                setFieldValue("user", e);
                              }} />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className=" row mb-4">
                            <MyDatePicker
                              label='Fecha:' name="date" mode='horizontal'
                              className="form-control fc-datepicker"
                              singleSelect="true"
                              placeholder="dd/mm/aaaa"
                              onChange={e => {
                                setFieldValue("date", e);
                              }} />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-12">
                          <div className=" row mb-4">
                            <Button variant="primary" type="submit">
                              Buscar
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </Form>
                  )
                }
              </Formik>
            </Card.Body>
          </Card>
        </div>
      </Row>
    </div>
  );
}
