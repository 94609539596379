import { useState } from "react";
import {
  addIndividualQuote, getQuoteById, getQuotesListByIdIndividualFile
} from "../../../../store/slices/quotes/thunks";
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Quote } from "./Quote";
import styles from './Quotes.module.css'
import { Header } from "../Header/Header";
import { MasterSaleDetails } from "../MasterSale/MasterSaleDetails";
import { setQuote, setQuotesList } from "../../../../store/slices/quotes/quotesSlice";
import { getMasterSaleByIndividualFile } from "../../../../store/slices/individualFiles/thunks";

export const Quotes = ({ individualFile }) => {
  const dispatch = useDispatch();
  const { quotesList, quote } = useSelector((state) => state.quotes);
  const { masterSale } = useSelector((state) => state.individualFiles);
  const [quoteSelected, setQuoteSelected] = useState("");

  useEffect(() => {
    if (individualFile.id) {
      dispatch(getQuotesListByIdIndividualFile(individualFile.id))
    }

    return () => {
      dispatch(setQuotesList([]));
      dispatch(setQuote({}));
    }
  }, [individualFile.id])

  useEffect(() => {
    if (individualFile.id) {
      dispatch(getMasterSaleByIndividualFile(individualFile.id))
    }
  }, [individualFile.id])

  useEffect(() => {
    if (quoteSelected) {
      dispatch(getQuoteById(quoteSelected))
    }
  }, [quoteSelected])

  return (
    <>

      <Header individualFile={individualFile} />

      {
        (masterSale.isOpen === 1 && masterSale.id) && <MasterSaleDetails masterSale={masterSale} individualFile={individualFile} />
      }


      <button className="btn btn-light btn-icon" style={{ marginTop: 20 }}
        onClick={() => dispatch(addIndividualQuote(individualFile.id))} >
        <span>
          <i className="fe fe-plus"></i>&nbsp;
        </span>
        Crear Cotizacion
      </button>

      <div className="d-flex" style={{ marginTop: 20, marginBottom: 20 }} >
        {
          quotesList.map(quote => (
            <button key={quote.id} className={`btn  ${quoteSelected === quote.id ? "btn-info text-white" : "btn-light"}`}
              onClick={() => setQuoteSelected(quote.id)} >
              Cotizacion #{quote.number}
            </button>
          ))
        }
      </div>

      {
        quote.id && <Quote quote={quote} key={quote.id} />
      }

    </>
  )
}
