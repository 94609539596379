import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../../ui/controls/MySelect";
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { MyDatePicker } from "../../ui/controls/MyDatePicker";
import { MyTextArea2 } from "../../ui/controls/MyTextArea2";
import { onlyIntegersDecimals } from "../../../helpers/numericFields";
import { useSelector, useDispatch } from 'react-redux';
import { getPaymentWayList } from "../../../store/slices/general/thunks";
import { MyCheckBox2 } from "../../ui/controls/MyCheckBox2";
import { deletePendingPayment, editPendingPayment, rejectPendingPayment } from "../../../store/slices/files/thunks";
import { MyFileInput } from "../../ui/controls/MyFileInput";
import { Image_Type } from "../../../const/Image"
import Swal from "sweetalert2";
import { useState } from "react";

export const EditAdvancePayment = ({ payment, isOpen, setIsOpen }) => {
  const { role } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { paymentMethodList, paymentWayList } = useSelector((state) => state.general);
  const [notes, setNotes] = useState(payment.notes);

  const delete2 = () => {
    const success = () => {
      setIsOpen(false)
    }
    dispatch(deletePendingPayment(payment.id, success));
  }

  const reject2 = () => {
    const success = () => {
      setIsOpen(false)
    }
    dispatch(rejectPendingPayment(payment.id, notes, success));
  }

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Pago De Cliente
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            date: payment.date,
            amount: payment.amount,
            paymentMethod: { "value": payment.idPaymentMethod, "label": payment.paymentMethod },
            paymentWay: { "value": payment.idPaymentWay, "label": payment.paymentWay },
            reference: payment.reference || "",
            invoice: payment.invoice === 1 ? true : false,
            notes: payment.notes || "",
            approved: payment.approved === 1 ? true : false,
          }}
          onSubmit={(({ date, amount, paymentMethod, paymentWay, reference, invoice, notes, image2,
            approved }, { resetForm, setErrors }) => {

            if (!date) {
              return setErrors({ "date": "Requerido" })
            }
            if (!paymentMethod) {
              return setErrors({ "paymentMethod": "Requerido" })
            }
            if (!paymentWay) {
              return setErrors({ "paymentWay": "Requerido" })
            }

            let date2;
            if (date.year) {
              date2 = date.year + "-" + (date.monthIndex + 1) + "-" + date.day
            } else {
              const temp = date.split("-")
              date2 = temp[2] + "-" + temp[1] + "-" + temp[0]
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(editPendingPayment(payment.id, date2, amount, paymentWay.value,
              reference, image2, (invoice || invoice === 1 ? 1 : 0), notes,
              (approved || approved === 1 ? 1 : 0), success));
          })}
          validationSchema={
            Yup.object({
              amount: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fecha de pago:*' name="date" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa"
                          onChange={e => {
                            setFieldValue("date", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Monto:*' name='amount' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("amount", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Metodo:*' name="paymentMethod" options={paymentMethodList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("paymentMethod", e);
                            dispatch(getPaymentWayList(e.value))
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Forma de pago:*' name="paymentWay" options={paymentWayList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("paymentWay", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Referencia:' name='reference' mode='horizontal'
                          onChange={(e) => {
                            setFieldValue("reference", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <MyFileInput label='Comprobante' name='image' mode='horizontal'
                        onChange={(e) => {
                          if (e.target.files[0]) {
                            if (Image_Type.indexOf(e.target.files[0].type) === -1) {
                              setFieldValue("image2", undefined)
                              return Swal.fire("Pagos", "Formato no valido", "error");
                            }
                            if (e.target.files[0].size > 2000000) {
                              setFieldValue("image2", undefined)
                              return Swal.fire("Pagos", "Solo se permiten imagenes de 2MB max", "error");
                            }
                            setFieldValue("image2", e.currentTarget.files[0])
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12" >
                      <div className=" row mb-4">
                        <MyCheckBox2 label="Facturar:" name="invoice" mode='horizontal' style={{ fontSize: 20, marginTop: 8 }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12" >
                      <div className=" row mb-4">
                        <MyCheckBox2 label="Aprobado:" name="approved" mode='horizontal' style={{ fontSize: 20, marginTop: 8 }} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={4}
                          onChange={(e) => {
                            setFieldValue("notes", e.target.value);
                            setNotes(e.target.value);
                          }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Row style={{ width: "100%" }}>
                    <div className="col-lg-4 col-md-4 col-4">
                      {
                        role === "DIRECTOR"
                        && <Button variant="danger" type="button" onClick={delete2}>
                          Eliminar
                        </Button>
                      }
                    </div>
                    <div className="col-lg-4 col-md-3 col-3" style={{ textAlign: "center" }}>
                      <Button variant="warning" type="button" onClick={reject2}>
                        Rechazar
                      </Button>
                    </div>
                    <div className="col-lg-4 col-md-5 col-5" style={{ textAlign: "right" }}>
                      <Button variant="primary" type="submit">
                        Guardar Informacion
                      </Button>
                    </div>
                  </Row>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>
      </Modal>
    </>
  )
}
