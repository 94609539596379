import { useDispatch, useSelector } from 'react-redux';
import { ViewImages } from '../Guest/ViewImages'
import styles from './Header.module.css'
import { useState } from 'react';
import { hotelImages } from '../../store/slices/group/thunks';


export const Header = ({info}) => {
  const dispatch = useDispatch();
  const [ viewImages, setViewImages ] = useState(false);
  const { hotelImages: images } = useSelector((state) => state.group);
  

  const showImages = () => {
    dispatch(hotelImages(info.code));
    setViewImages(true);
  }

  return (
    <div style={{ marginTop: 10, marginBottom: 20 }}>
      <div className={`${styles.title}`}>Grupo: {info.group2}</div>
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        <div>
          <div className={`${styles.subTitleBlock}`}>
            <label className={`${styles.subTitle}`}>Cliente:</label><label>{info.customer}</label>
          </div>
          <div className={`${styles.subTitleBlock}`}>
            <label className={`${styles.subTitle}`}>Destino:</label><label>{info.tags}</label>
          </div>
        </div>
        <div>
          <button className={`${styles.images} btn btn-info`} onClick={showImages}>Imagenes de hotel</button>
        </div>
      </div>      
      <ViewImages images={images} isOpen={viewImages} setIsOpen={setViewImages} />
    </div>    
  )
}
