import { useState } from 'react';
import { currencyFormat } from '../../helpers/numericFields'
import { EditCabin } from './EditCabin';
import styles from './CabinDetails.module.css'

export const CabinDetails = ({ cabin, idQuote }) => {
  const [editCabin, setEditCabin] = useState(false);
  return (
    <>    
      <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Cabina:</div>      
      <div className={`col-lg-5 col-md-5 ${styles.edit}`} onClick={() => setEditCabin(true)}>{cabin.cabin}</div>       
      <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Tarifa Publica:</div>
      <div className={`col-lg-3 col-md-3`}>{cabin.currency} {currencyFormat(cabin.rate)}</div>
      <div className={`col-lg-7 col-md-7`}></div>            
      <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Tarifa Neta:</div>
      <div className={`col-lg-3 col-md-3`}>{cabin.currency} {currencyFormat(cabin.netPrice)}</div>
      <div className={`col-lg-7 col-md-7`}></div>
      <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Impuestos:</div>
      <div className={`col-lg-3 col-md-3`}>{cabin.currency} {currencyFormat(cabin.tax)}</div>      
      <div className={`col-lg-7 col-md-7`}></div>
      <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Propinas:</div>
      <div className={`col-lg-3 col-md-3`}>{cabin.currency} {currencyFormat(cabin.tip)}</div>
      <div className={`col-lg-7 col-md-7`}></div>
      <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Total:</div>
      <div className={`col-lg-3 col-md-3`}>{cabin.currency} {currencyFormat(cabin.rate + cabin.tax + cabin.tip)}</div>
      <div style={{height:15}} />
      <EditCabin cabin={cabin} idQuote={idQuote} isOpen={editCabin} setIsOpen={setEditCabin} />
    </>
  )
}
