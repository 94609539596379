import { Table } from "react-bootstrap";
import { currencyFormat } from "../../helpers/numericFields";
import styles from './MasterSaleDetails.module.css'

export const IndividualFilesDetails = ({ files }) => {

  return (
    <>
      <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
        <div className="table-responsive">
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>#</th>
                <th># Expediente</th>
                <th>Cliente</th>
                <th>Total</th>
                <th>Pagado</th>
                <th>Saldo</th>
                <th>Estatus</th>
              </tr>
            </thead>
            <tbody>
              {
                files.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index+1}</td>
                    <td style={{ textAlign: "center" }}>{item.id}</td>
                    <td>{item.customer}</td>
                    <td>{currencyFormat(item.publicPrice)}</td>
                    <td>{currencyFormat(item.paid)}</td>
                    <td>{currencyFormat(item.paid - item.publicPrice)}</td>
                    <td style={{ textAlign: "center" }}>
                    <i className={`fa fa-circle ${styles.edit}`} 
                      style={{fontSize:18, 
                        color: (item.status === "Al corriente" ? "green" : "red")}} ></i>
                  </td>
                  </tr>))
              }                          
            </tbody>
          </Table>
        </div>
      </div>
    </>
  )
}
