import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { EditIndividualFile } from '../EditIndividualFile';
import styles from './Header.module.css'

export const Header = ({ individualFile }) => {
  const [editIndividualFile, setEditIndividualFile] = useState(false);

  return (
    <>
      <div className="table-responsive">
        <Table className="table border text-nowrap text-md-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Agente</th>
              <th>Medio</th>
              <th>Estatus</th>
              <th>Cliente</th>
              <th>Telefono</th>
              <th>Celular</th>
              <th>Fechas Viaje</th>
              <th>Destino</th>
              <th>Categoria</th>
              <th>Fecha</th>
              <th>Expedientes</th>
              <th>Dueño</th>
              <th>Codigo</th>
              <th>Editar</th>
              <th>Pagos</th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td>{individualFile.agent}</td>
              <td>{individualFile.contactWay}</td>
              <td className={`text-${individualFile.status === "Saldo" ? "yellow" : "green"}`}>{individualFile.status}</td>
              <td>{individualFile.customer}</td>
              <td>{individualFile.telephone}</td>
              <td>{individualFile.cellphone}</td>
              <td>{individualFile.startDate + " - " + individualFile.endDate}</td>
              <td>{individualFile.tags}</td>
              <td>{individualFile.category}</td>
              <td>{individualFile.requestDate}</td>
              <td>{individualFile.files}</td>
              <td style={{ textAlign: "center" }}>
                {individualFile.owner ? <i className="fa fa-check" /> : ""}
              </td>
              <td>{individualFile.code}</td>
              <td style={{ textAlign: "center" }}>
                <i className={`fe fe-edit ${styles.edit}`} onClick={() => setEditIndividualFile(true)}></i>
              </td>
              <td style={{ textAlign: "center" }}>
                <i className={`fe fe-download ${styles.edit}`} onClick={() => setEditIndividualFile(true)}></i>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <EditIndividualFile individualFile={individualFile} isOpen={editIndividualFile} setIsOpen={setEditIndividualFile} />
    </>
  )
}
