const gregorian_es = {
  name: "gregorian_en_lowercase",
  months: [
    ["Enero", "Ene"],
    ["Febrero", "Feb"],
    ["Marzo", "Mar"],
    ["Abril", "Abr"],
    ["Mayo", "May"],
    ["Junio", "Jun"],
    ["Julio", "Jul"],
    ["Agosto", "Ago"],
    ["Septiembre", "Sep"],
    ["Octubre", "Cct"],
    ["Noviembre", "Nov"],
    ["Diciembre", "Dec"],
  ],
  weekDays: [
    ["Sabado", "Sab"],
    ["Domingo", "Dom"],
    ["Lunes", "Lun"],
    ["Martes", "Mar"],
    ["Miercoles", "Mie"],
    ["Jueves", "Jue"],
    ["Vienes", "Vie"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
};

export default gregorian_es;