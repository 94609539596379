import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AirportHotelDetails } from './AirportHotelDetails'
import { getPendingAirportHotel } from '../../../store/slices/groupFiles/thunks';
import { getPendingAirportHotel as getPendingAirportHotel2 } from '../../../store/slices/individualFiles/thunks';

export const AirportHotel = () => {
  const dispatch = useDispatch();
  const { pendingAirportHotel } = useSelector((state) => state.groupFiles);
  const { pendingAirportHotel: pendingAirportHotel2 } = useSelector((state) => state.individualFiles);
  
  useEffect(() => {
    dispatch(getPendingAirportHotel())
  }, [])

  useEffect(() => {
    dispatch(getPendingAirportHotel2())
  }, [])
  

  return (
    <div style={{marginTop:20}}>
      <div style={{ fontSize: 20 }}>Solicitudes pendientes por aprobar</div>
      <AirportHotelDetails pendingAirportHotel={pendingAirportHotel} pendingAirportHotel2={pendingAirportHotel2} />
    </div>
  )
}
