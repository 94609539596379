import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextArea2 } from "../../../ui/controls/MyTextArea2";
import { useDispatch } from 'react-redux';
import { editQuoteDescription } from "../../../../store/slices/quotes/thunks";
import { editRoomNotes } from "../../../../store/slices/individualFiles/thunks";

export const EditDescription = ({ room, individualFile, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Notas En Habitacion
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            notes: room.notes,
          }}
          onSubmit={(({ notes }, { resetForm, setErrors }) => {

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(editRoomNotes(room.id, notes, individualFile.id, success));
          })}
          validationSchema={
            Yup.object({

            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={2} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <div style={{ width: "100%" }}>
                    <Button variant="primary" type="submit" style={{ float: "right" }}>
                      Guardar Informacion
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
