import { viajesApi } from "../../../api/viajesApi";
import Swal from "sweetalert2";
import { setEnd, setLogin, setLogout, setStart } from "./authSlice";



export const login = (userName, password) => {
  return async (dispatch, getState) => {

    try {
      await viajesApi.post("/auth/login", {
        userName, password
      });
      dispatch(checkLogin());
    } catch (error) {
      Swal.fire("Autenticacion", error.response.data.msg, "error");
    }
  }
}

export const checkLogin = () => {
  return async (dispatch) => {
    dispatch(setStart());
    try {
      const resp = await viajesApi.get("/auth/checkLogin");
      const result = resp.data.results;
      if (JSON.stringify(result) !== JSON.stringify({})) {
        dispatch(setLogin({
          name: result.name,
          role: result.role,
          image: result.image,
        }));
      } else {
        dispatch(setEnd());
      }
    } catch (error) {
      console.log(error)
      dispatch(setEnd());
    }
  }
}

export const logout = () => {
  return async(dispatch) => {
    try {
      await viajesApi.delete("/auth/logout");
      dispatch(setLogout());
    } catch (error) {
      Swal.fire("Cierre de sesion", error.response.data.msg, "error");
    }
  }
}