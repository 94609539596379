import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from "react-router-dom"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useSelector } from 'react-redux';

export const SearchGroupFileTable = () => {
  const { groupFilesList } = useSelector((state) => state.groupFiles);

  const columns = [
    {
      name: "#Exp",
      selector: row => [row.id],
      sortable: true,
      width:"85px",
      cell: row => <span className="text-center w-100">
        <OverlayTrigger placement="top" overlay={<Tooltip >Ver Expediente</Tooltip>}>
          <Link to={`/panel/expedienteGrupo?id=${row.id}`} className="btn btn-primary btn-sm rounded-11 me-2" ><span className="fs-15 fw-semibold text-center">{row.id}</span></Link>
        </OverlayTrigger>
      </span>
    },    
    {
      name: "Nombre",
      selector: row => [row.name],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.name}</span>
    },
    {
      name: "Cliente",
      selector: row => [row.customer],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.customer}</span>
    },
    {
      name: "Habitaciones",
      selector: row => [row.rooms],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center w-100">{row.rooms}</span>
    },
    {
      name: "Pasajeros",
      selector: row => [row.passengers],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center w-100">{row.passengers}</span>
    },
    {
      name: "Categoria",
      selector: row => [row.category],
      sortable: true, 
      width:"100px",
      cell: row => <span className="text-muted fs-15 fw-semibold text-center w-100">{row.category}</span>
    },
    {
      name: "Fecha Inicio",
      selector: row => [row.startDate],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.startDate}</span>
    },
    {
      name: "Fecha Fin",
      selector: row => [row.endDate],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.endDate}</span>
    },
    {
      name: "Destinos",
      selector: row => [row.tags],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.tags}</span>
    },
    {
      name: "Notas",
      selector: row => [row.notes],
      sortable: true,
      cell: row => <span className="text-center w-100">{row.notes && <i className="fa fa-check" />}
      </span>
    }
  ]
  const tableDatas = {
    columns,
    data:groupFilesList,
  };
  return (
    <DataTableExtensions {...tableDatas} >
      <DataTable
        columns={columns}
        data={groupFilesList}
        noHeader
        defaultSortField="id"
        defaultSortAsc={false}
        striped={true}
        center={true}
        persistTableHead
        pagination
        highlightOnHover />
    </DataTableExtensions>
  )
}