import { viajesApi } from "../../../api/viajesApi";
import { setInfo } from "./travelerSlice";
import Swal from "sweetalert2";
import fileDownload from 'js-file-download'

export const search = (code, success) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/traveler/search", {
        params: {
          code
        }
      });
      success();
      dispatch(setInfo(resp.data.results));
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
      dispatch(setInfo({}));
    }
  }
}

export const addPaymentMasterSale = (idMasterSaleFile, date, amount, idPaymentWay, image,
 notes, code, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("idMasterSaleFile", idMasterSaleFile);
      form.append("date", date);
      form.append("amount", amount);
      form.append("idPaymentWay", idPaymentWay);
      if (image)
        form.append("image", image, image.Name);
      form.append("notes", notes ? notes : "");

      const resp = await viajesApi.post("/traveler/addPaymentMasterSale", form);
      success();
      dispatch(search(code, () => {}));
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const travelVoucher = (id, code) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/traveler/travelVoucherById", {
        params: {
          id, code
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Recibo de viaje.pdf`);
      Swal.fire("Archivos", "Comprobante descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", "Ha ocurrido un error", "error");
    }
  }
}