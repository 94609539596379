import { useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { currencyFormat } from "../../../helpers/numericFields";
import styles from './Summary.module.css'
import { SummaryDetails } from "./SummaryDetails";

export const Summary = () => {
  const { roomingList } = useSelector((state) => state.groupFiles);

  return (
    <div style={{marginTop:20}}>
      <div style={{ fontSize: 20 }}>Habitaciones Vendidas</div>
      <SummaryDetails roomingList={roomingList} />
    </div>
  )
}
