import React, { useEffect, useState } from 'react'
import * as colors from '../../../../const/colors'
import { AddAir } from '../../../Quote/AddAir';
import { AddLodging } from '../../../Quote/AddLodging';
import { AddCruise } from '../../../Quote/AddCruise';
import { AirDetails } from '../../../Quote/AirDetails';
import styles from './Quote.module.css'
import { LodgingDetails } from '../../../Quote/LodgingDetails';
import { CruiseDetails } from '../../../Quote/CruiseDetails';
import { EditDescription } from '../../../Quote/EditDescription';
import { ServiceDetails } from '../../../Quote/ServiceDetails';
import { currencyFormat } from '../../../../helpers/numericFields';
import { AddService } from '../../../Quote/AddService';
import { addQuoteToSale } from '../../../../store/slices/quotes/thunks';
import { useDispatch, useSelector } from 'react-redux';


export const Quote = ({ quote }) => {
  const dispatch = useDispatch();
  const [editDescription, setEditDescription] = useState(false);
  const [addAir, setAddAir] = useState(false);
  const [addLodging, setAddLodging] = useState(false);
  const [addCruise, setAddCruise] = useState(false);
  const [addService, setAddService] = useState(false);
  const { masterSale } = useSelector((state) => state.individualFiles);

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <div style={{ color: colors.title }} className={styles.title}>{`Cotizacion #${quote.number}`}</div>
        {
          masterSale.isOpen 
          ?
            quote.accepted
              ? <button className={`btn text-white btn-secondary`}
                onClick={() => dispatch(addQuoteToSale(quote.id, false))} >Remover De Venta</button>
              : <button className={`btn text-white btn-primary`}
                onClick={() => dispatch(addQuoteToSale(quote.id, true))} >Agregar A Venta</button>
          : <></>
        }
      </div>
      <div style={{ padding: 10 }}>
        <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Descripcion</div>
        {
          quote.description
            ? <div className={`${styles.edit}`} onClick={()=> setEditDescription(true)}>{quote.description}</div>
            : <div className={`${styles.edit}`} onClick={()=> setEditDescription(true)}>Agregar Descripcion</div>
        }
      </div>
      <div style={{ marginTop: 20 }}>
        <button className="btn btn-light" onClick={() => setAddAir(true)} >+Avion</button>
        <button className="btn btn-light" onClick={() => setAddLodging(true)} >+Hotel</button>
        <button className="btn btn-light" onClick={() => setAddCruise(true)} >+Crucero</button>
        <button className="btn btn-light" onClick={() => setAddService(true)} >+Servicio</button>
        <AddAir quote={quote} isOpen={addAir} setIsOpen={setAddAir} />
        <AddLodging quote={quote} isOpen={addLodging} setIsOpen={setAddLodging} />
        <AddCruise quote={quote} isOpen={addCruise} setIsOpen={setAddCruise} />
        <AddService quote={quote} isOpen={addService} setIsOpen={setAddService} />
      </div>

      {
        quote.air.length > 0 && <div>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Aereos</div>
          {
            quote.air.map((air, index) => (
              <div key={air.id}>
                <AirDetails air={air} index={index} />
              </div>
            ))
          }
        </div>
      }
      {
        quote.lodging.length > 0 && <div>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Hospedaje</div>
          {
            quote.lodging.map((lodging, index) => (
              <div key={lodging.id}>
                <LodgingDetails lodging={lodging} index={index} />
              </div>
            ))
          }
        </div>
      }
      {
        quote.cruise.length > 0 && <div>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Cruceros</div>
          {
            quote.cruise.map((cruise, index) => (
              <div key={cruise.id}>
                <CruiseDetails cruise={cruise} index={index} />
              </div>
            ))
          }
        </div>
      }
      {
        quote.service.length > 0 && <div>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Servicios</div>
          {
            quote.service.map((service, index) => (
              <div key={service.id}>
                <ServiceDetails service={service} index={index} />
              </div>
            ))
          }
        </div>
      }
      {
        quote.total.map(item => (
          <div key={item.currency} className={`row`}>
            <div className={`col-lg-3 col-md-3 ${styles.total}`} style={{ color: colors.title }}>Total: {item.currency}</div>
            <div className={`col-lg-9 col-md-9 ${styles.total}`} style={{ color: colors.title }}>{currencyFormat(item.value)}</div>
          </div>
        ))
      }
      <EditDescription quote={quote} isOpen={editDescription} setIsOpen={setEditDescription} />
    </div>
  )
}
