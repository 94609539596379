import { useState, useEffect } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../../ui/controls/MySelect";
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { MyDatePicker } from "../../ui/controls/MyDatePicker";
import { MyTextArea2 } from "../../ui/controls/MyTextArea2";
import { onlyIntegers } from "../../../helpers/numericFields";
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment'
import { deleteLodgingGroupFile, editLodgingGroupFile } from "../../../store/slices/groupFiles/thunks";
import { MyTextLabel } from "../../ui/controls/MyTextLabel";
import { MyTextInput66 } from "../../ui/controls/MyTextInput66";

export const EditLodging = ({ lodging, groupFile, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { wholesalersList, hotelsList, lodgingTypeList } = useSelector((state) => state.general);
  const [daysNights, setDaysNights] = useState({ days: groupFile.days, nights: groupFile.nights });

  const delete2 = () => {
    const success = () => {
      setIsOpen(false)
    }
    dispatch(deleteLodgingGroupFile(lodging.id, groupFile.id, success));
  }

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Hospedaje
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            checkInCheckOutDate: [lodging.checkInDate, lodging.checkOutDate],
            lodgingType: {value: lodging.idLodgingType, label: lodging.lodgingType},
            reserve: lodging.reserve,
            locator: lodging.locator,
            hotel: {value: lodging.idHotel, label: lodging.hotel},
            wholesaler: {value: lodging.idWholesaler, label: lodging.wholesaler},       
            daysBefore: lodging.daysBefore,
            daysAfter: lodging.daysAfter,
            notes: lodging.notes,
          }}
          onSubmit={(({ checkInCheckOutDate, lodgingType, reserve, locator, hotel, wholesaler, daysBefore, 
            daysAfter, notes }, { resetForm, setErrors }) => {              

            let checkInDate;
            if (checkInCheckOutDate[0].year) {
              checkInDate = checkInCheckOutDate[0].year + "-" + (checkInCheckOutDate[0].monthIndex + 1) + "-" + checkInCheckOutDate[0].day
            } else {
              const temp = checkInCheckOutDate[0].split("-")
              checkInDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }
            let checkOutDate;
            if (checkInCheckOutDate[1].year) {
              checkOutDate = checkInCheckOutDate[1].year + "-" + (checkInCheckOutDate[1].monthIndex + 1) + "-" + checkInCheckOutDate[1].day
            } else {
              const temp = checkInCheckOutDate[1].split("-")
              checkOutDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }

            if (!checkInCheckOutDate || checkInCheckOutDate.length !== 2) {
              return setErrors({ "checkInCheckOutDate": "Requerido" })
            }
            if (!lodgingType) {
              return setErrors({ "lodgingType": "Requerido" })
            }
            if (!hotel) {
              return setErrors({ "hotel": "Requerido" })
            }
            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(editLodgingGroupFile(lodging.id, checkInDate, checkOutDate, lodgingType.value, reserve, locator, 
              hotel.value, wholesaler ? wholesaler.value : "", daysBefore, daysAfter, notes, groupFile.id, success));
          })}
          validationSchema={
            Yup.object({
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Entrada y Salida:*' name="checkInCheckOutDate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                          range
                          onChange={e => {
                            setFieldValue("checkInCheckOutDate", e);
                            if (!e || e.length === 2) {
                              const date1 = new moment(e[0].day + "-" + (e[0].monthIndex + 1) + "-" + e[0].year, "DD-MM-YYYY")
                              const date2 = new moment(e[1].day + "-" + (e[1].monthIndex + 1) + "-" + e[1].year, "DD-MM-YYYY")
                              setDaysNights({ days: date2.diff(date1, 'days') + 1, nights: date2.diff(date1, 'days') })
                            }
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Tipo de hospedaje:*' name="lodgingType" options={lodgingTypeList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("lodgingType", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Reserva:' name='reserve' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Localizador:' name='locator' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Hotel:*' name="hotel" options={hotelsList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("hotel", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Mayorista:' name="wholesaler" options={wholesalersList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("wholesaler", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel label='Noches:' mode='horizontal' value={daysNights.nights} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel label='Dias:' mode='horizontal' value={daysNights.days} />
                      </div>
                    </div>                    
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Misma Tarifa Dias Antes:' name='daysBefore' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("daysBefore", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Misma Tarifa Dias Despues:' name='daysAfter' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("daysAfter", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={2} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <div style={{ width: "100%" }}>
                    <Button variant="danger" type="button" style={{ float: "left" }} onClick={delete2}>
                      Eliminar
                    </Button>
                    <Button variant="primary" type="submit" style={{ float: "right" }}>
                      Guardar Informacion
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
