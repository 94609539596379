import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../../ui/controls/MySelect";
import { MyTextInput } from "../../ui/controls/MyTextInput";
import {  useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import {  addRequestHotelAirport } from "../../../store/slices/group/thunks";
import { useState } from "react";
import { MyTimePicker } from "../../ui/controls/MyTimePicker";

export const AddHotelAirport = ({ code, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const [names, setNames] = useState([])
  const [name, setName] = useState("")


  const addName = () => {
    if(!name) return;

    if(names.find(x => x == name)){
      Swal.fire("Transporte", "Esta persona ya está agregada", "error");
      return;
    }
    setNames([...names, name])
    setName("");
  }

  const removeName = (name) => {
    let names2 = names.filter(x => x != name);
    setNames(names2)
  }

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Solicitar Transporte Hotel - Aeropuerto
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            flight: "",
            airline: "",
            departureDate: undefined,
            terminal: "",
            privatePublic: undefined,
          }}
          onSubmit={(({ flight, airline, departureDate, terminal, privatePublic
          }, { resetForm, setErrors }) => {

            if (!departureDate) {
              return setErrors({ "departureDate": "Requerido" })
            }

            if (!privatePublic || privatePublic.value == "0") {
              return setErrors({ "privatePublic": "Requerido" })
            }

            if(names.length == 0){
              Swal.fire("Transporte", "Favor de colocar el nombre de las personas a transportar", "error");
              return;
            }

            let date2 = new Date();

            if(departureDate < date2){
              Swal.fire("Transporte", "Fecha invalida", "error");
              return;
            }

            let departureDate2 = departureDate.year + "-" + (departureDate.monthIndex + 1) + "-" + departureDate.day
             + " " + departureDate.hour + ":" + departureDate.minute;

            const success = () => {
              resetForm();
              setNames([]);
              setIsOpen(false);
            }

            dispatch(addRequestHotelAirport( code, flight, airline, departureDate2, terminal, 
              privatePublic.value, names.length, names.join(", "), success));
          })}
          validationSchema={
            Yup.object({
              flight: Yup.string()
                .required("Requerido"),
              airline: Yup.string()
                .required("Requerido"),
              terminal: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Vuelo:*' name='flight' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Aerolinea:*' name='airline' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTimePicker
                          label='Salida vuelo:*' name="departureDate" mode='horizontal'
                          className="form-control fc-datepicker"
                          placeholder="dd/mm/aaaa hh:mm AM/PM"
                          onChange={e => {
                            setFieldValue("departureDate", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Terminal:*' name='terminal' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Modo:*' name="privatePublic" options={[{ value: "Privado", label: "Privado" }, { value: "Compartido", label: "Compartido" }]} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("privatePublic", e);
                          }} />
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-lg-8 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Nombre persona:' name='name' mode='horizontal'
                          onChange={e => {
                            setName(e.target.value)
                            setFieldValue("name", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className=" row mb-4">
                        <button type="button" className="btn btn-primary btn-icon"
                          onClick={() => {
                            addName();
                            setFieldValue("name", "");
                          }} >Agregar persona</button>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <table className="table table-striped table-bordered table-sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Remover</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            names.map((x, index) => (
                              <tr key={x + "" + index}>
                                <td style={{width:50, textAlign:"center"}}>{index + 1}</td>
                                <td>{x}</td>
                                <td style={{width:100, textAlign:"center"}}><button type="button" className="btn btn-danger btn-icon" style={{padding:"0px"}}
                                  onClick={() => {
                                    removeName(x)
                                  }} >-</button></td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>

                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
