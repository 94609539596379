import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addHotelImage, deleteHotelImage } from "../../../store/slices/catalogue/thunks";
import { MyFileInput } from "../../ui/controls/MyFileInput";
import { Image_Type } from "../../../const/Image";
import Swal from "sweetalert2";
import placeHolder from '../../../images/placeholder-image.png';
import style from './HotelImages.module.css';

export const HotelImages = ({ hotel, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { hotelImagesList } = useSelector((state) => state.catalogue);

  const deleteImage = (image) => {
    dispatch(deleteHotelImage(image, hotel.id));
  }

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Imagenes
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>


        <Modal.Body>
          <Row>

            <Formik
              initialValues={{
                image: undefined,
              }}
              onSubmit={(({ image2
              }, { resetForm, setErrors }) => {


                if (!image2) {
                  return setErrors({ "image": "Requerido" })
                }


                const success = () => {
                  resetForm();
                  setIsOpen(false)
                }

                dispatch(addHotelImage(hotel.id, image2, success));
              })}
              validationSchema={
                Yup.object({

                })
              }
            >

              {
                ({ setFieldValue }) => (
                  <Form>
                    <Row>
                      <div className="col-lg-8 col-md-12">
                        <MyFileInput label='Imagen*' name='image' mode='horizontal'
                          onChange={(e) => {
                            if (e.target.files[0]) {
                              if (Image_Type.indexOf(e.target.files[0].type) === -1) {
                                setFieldValue("image2", undefined)
                                return Swal.fire("Imagenes", "Formato no valido", "error");
                              }
                              if (e.target.files[0].size > 2000000) {
                                setFieldValue("image2", undefined)
                                return Swal.fire("Imagenes", "Solo se permiten imagenes de 2MB max", "error");
                              }
                              setFieldValue("image2", e.currentTarget.files[0])
                            }
                          }}
                        />
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <Button variant="primary" type="submit">
                          Guardar Imagen
                        </Button>
                      </div>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Row>
          <br />
          <Row>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Imagen</th>                  
                  <th>Eliminar</th>
                </tr>
              </thead>
              <tbody>
                {
                  hotelImagesList.map((image, index) => (
                    <tr key={image.image}>
                      <td>{index + 1}</td>
                      <td><img
                        className={style.image}
                        src={process.env.REACT_APP_URL_IMAGES_HOTEL + '/' + image.image}
                        onError={(e) => e.target.src = placeHolder}
                        alt={"hotel"} />
                      </td>                      
                      <td><button className="btn btn-danger"
                        onClick={() => deleteImage(image.image)}>Eliminar</button></td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </Row>
          <br />
          <br />
          <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
        </Modal.Body>

        <Modal.Footer>
        </Modal.Footer>


      </Modal>
    </>
  );
}
