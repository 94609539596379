import { useState } from 'react';
import { Row } from 'react-bootstrap'
import { currencyFormat } from '../../helpers/numericFields'
import styles from './AirDetails.module.css'
import { EditAir } from './EditAir';

export const AirDetails = ({ air, index }) => {  
  const [editAir, setEditAir] = useState(false);

  return (
    <>
      <Row className={styles.container}>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Aerolinea:</div>
        <div className={`col-lg-4 col-md-4 ${styles.edit}`} onClick={() => setEditAir(true)}>{air.airline}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Mayorista:</div>
        <div className="col-lg-4 col-md-4">{air.wholesaler}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Ida:</div>
        <div className="col-lg-4 col-md-4">{air.departureDate}</div>
        <div className="col-lg-6 col-md-6">{air.outboundItinerary}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Vuelta:</div>
        <div className="col-lg-4 col-md-4">{air.returnDate}</div>
        <div className="col-lg-6 col-md-6">{air.returnItinerary}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Notas:</div>
        <div className="col-lg-10 col-md-10">{air.notes}</div>
        <div style={{ height: 20 }}></div>
        <div className="col-lg-7 col-md-7"></div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Tarifa:</div>
        <div className="col-lg-3 col-md-3">{air.currency} {currencyFormat(air.rate)}</div>
        <div className="col-lg-7 col-md-7"></div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Emision:</div>
        <div className="col-lg-3 col-md-3">{air.currency} {currencyFormat(air.fee)}</div>
        <div className="col-lg-7 col-md-7"></div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle} ${styles.subTotal}`}>Sub Total:</div>
        <div className={`col-lg-3 col-md-3 ${styles.subTotal}`}>{air.currency} {currencyFormat(air.rate + air.fee)}</div>
        <div style={{ height: 10 }}></div>
        <div className={`col-12 ${styles.line}`}></div>
      </Row>
      <EditAir air={air} isOpen={editAir} setIsOpen={setEditAir} />
    </>
  )
}
