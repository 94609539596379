import { Card, Col, Row, Table } from "react-bootstrap";

export const RoomControl = ({ hotel }) => {

  return (
    <>
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h3 className="card-title mb-0">Habitaciones</h3>
            </Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <Table className="table text-nowrap text-md-nowrap mg-b-0">
                  <thead>
                    <tr>
                      <th>Cantidad</th>
                      <th>Cuenta</th>
                      <th>Cuartos Noche</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr >
                      <td>{hotel.quantity}</td>
                      <td>Maestra</td>
                      <td>{hotel.quantity * hotel.nights}</td>
                    </tr>
                    <tr >
                      <td>{hotel.soldQuantity}</td>
                      <td>Vendidas</td>
                      <td>{hotel.soldNights}</td>
                    </tr>
                    <tr >
                      <td>{hotel.quantity - hotel.soldQuantity}</td>
                      <td>Por vender</td>
                      <td>{(hotel.quantity * hotel.nights) - hotel.soldNights}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}
