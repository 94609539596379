import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../../ui/controls/MySelect";
import { useSelector, useDispatch } from 'react-redux';
import '../../../assets/css/reactTags.css'
import { MyDatePicker } from "../../ui/controls/MyDatePicker";
import { editIndividualFile } from "../../../store/slices/individualFiles/thunks";
import { MyCheckBox3 } from "../../ui/controls/MyCheckBox3";

export const EditIndividualFile = ({ individualFile, isOpen, setIsOpen }) => {  
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.auth);
  const { customersList } = useSelector((state) => state.general);

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Expediente Individual
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            customer: { "value": individualFile.idCustomer, "label": individualFile.customer },
            requestDate: individualFile.requestDate,
            owner: individualFile.owner === 1 ? true : false,
            delete: false,
          }}
          onSubmit={(({ customer, owner, requestDate, remove },
            { resetForm, setErrors }) => {

            if (!customer) {
              return setErrors({ "customer": "Requerido" })
            }

            if (!requestDate) {
              return setErrors({ "requestDate": "Requerido" })
            }

            let requestDate2;
            if (requestDate.year) {
              requestDate2 = requestDate.year + "-" + (requestDate.monthIndex + 1) + "-" + requestDate.day
            } else {
              const temp = requestDate.split("-")
              requestDate2 = temp[2] + "-" + temp[1] + "-" + temp[0]
            }

            const success = () => {
              resetForm();
              setIsOpen(false);
            }

            dispatch(editIndividualFile(individualFile.id, customer.value, owner, requestDate2, remove, success));

          })}
          validationSchema={
            Yup.object({

            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Cliente:*' name="customer" options={customersList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("customer", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12" >
                      <div className=" row mb-4">
                        <MyCheckBox3 label="Pertenece al dueño:" name="owner" mode='horizontal' style={{ fontSize: 20, marginTop: 8 }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fecha Solicitud:*' name="requestDate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa"
                          onChange={e => {
                            setFieldValue("requestDate", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12"></div>
                    {
                      role === "DIRECTOR"
                      && <div className="col-lg-6 col-md-12" >
                          <div className=" row mb-4">
                            <MyCheckBox3 label="Eliminar:" name="remove" mode='horizontal' style={{ fontSize: 20, marginTop: 8 }} />
                          </div>
                        </div>
                    }
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  );
}
