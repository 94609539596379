import { useState } from "react";
import { Table } from "react-bootstrap";
import styles from './SeatingPlanDetails.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { AddGuest } from "./AddGuest";
import { DeleteTable } from "./DeleteTable";
import { EditGuest } from "./EditGuest";


export const SeatingPlanDetails = ({ seatingPlan, code, idGroupFile }) => {
  const { guestHighlighted, externalGuestHighlighted } = useSelector((state) => state.group);
  const [editGuest, setEditGuest] = useState(false);
  const [chairSelected, setChairSelected] = useState({});
  const [addGuest, setAddGuest] = useState(false);
  const [deleteTable, setDeleteTable] = useState(false);
  const [tableToDelete, setTableToDelete] = useState({});

  const addGuest2 = (chair) => {
    setAddGuest(true);
    setChairSelected(chair);
  }

  const editGuest2 = (idChair, idGuest, guest, idExternalGuest, externalGuest) => {
    setEditGuest(true);
    setChairSelected({
      idChair, idGuest, guest, idExternalGuest, externalGuest
    });
  }

  const deleteTable2 = (id, num) => {
    setDeleteTable(true);
    setTableToDelete({
      id, num
    });
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20, minWidth: "950px" }}>
      <div className={`${styles.staticTable} ${styles.coupleTable}`}>
        MESA DE NOVIOS
      </div>
      <div className={`${styles.tables} row`}>
        {
          seatingPlan.map((table, index) => (
            <div key={table.id} style={{ marginBottom: "200px" }} className={`col-6`}>
              {
                <div className={`${styles.table} ${index % 2 == 0 ? styles.tableLeft : styles.tableRight}`}>
                  <img className={styles.table2} src={require("../../../assets/images/draws/chair3.png")} alt="avatar" />
                  {
                    table.chairs.map((chair, index) => (                      
                      chair.idGuest
                        ? <div key={chair.id}>
                          <img className={`${styles.guest} ${styles["guest" + (index + 1)]} `}
                            src={require(`../../../assets/images/draws/${chair.idGuest == guestHighlighted ? 'guestHighlighted' : 'guest'}.png`)}
                            alt="avatar" />
                          <label className={`${styles.guestName} ${styles["guestName" + (index + 1)]} ${chair.idGuest == guestHighlighted ? styles.highlighted : null} `} onClick={() => editGuest2(chair.id, chair.idGuest, chair.guest)}>{chair.guest}</label>
                        </div>
                        : chair.idExternalGuest
                        ? <div key={chair.id}>
                          <img className={`${styles.guest} ${styles["guest" + (index + 1)]} `}
                            src={require(`../../../assets/images/draws/${chair.idExternalGuest == externalGuestHighlighted ? 'guestHighlighted' : 'guest'}.png`)}
                            alt="avatar" />
                          <label className={`${styles.externalGuestName} ${styles["guestName" + (index + 1)]} ${chair.idExternalGuest == externalGuestHighlighted ? styles.highlighted : null} `} 
                           onClick={() => editGuest2(chair.id, chair.idGuest, chair.guest, chair.idExternalGuest, chair.externalGuest)}>{chair.externalGuest}</label>
                        </div>
                        : <img key={chair.id} className={`${styles.add} ${styles["add" + (index + 1)]}`} src={require("../../../assets/images/draws/plus2.png")} alt="avatar" onClick={() => addGuest2(chair.id)} />
                    ))
                  }
                  <label className={`${styles.tableName}`}>Mesa {index + 1}</label>
                  <label className={`${styles.tableDelete}`} onClick={() => deleteTable2(table.id, index + 1)}>Eliminar</label>
                </div>
              }
            </div>
          ))
        }
      </div>
      <div className={`${styles.staticTable} ${styles.musicTable}`}>
        DJ O MUSICA EN VIVO
      </div>
      <AddGuest chair={chairSelected} code={code} idGroupFile={idGroupFile}
        isOpen={addGuest} setIsOpen={setAddGuest} />
      <EditGuest chair={chairSelected} code={code} idGroupFile={idGroupFile}
        isOpen={editGuest} setIsOpen={setEditGuest} />
      <DeleteTable idGroupFile={idGroupFile} code={code} isOpen={deleteTable} setIsOpen={setDeleteTable} table={tableToDelete} />
    </div>
  )
}
