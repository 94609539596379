import logo from '../../assets/images/brand/logoAccess.png'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from '../ui/controls/MyTextInput';
import styles from './AuthScreen.module.css'
import { useDispatch } from 'react-redux';
import { login } from '../../store/slices/auth/thunks';

export function AuthScreen() {
  const dispatch = useDispatch();

  return <div className={styles.authScreen}>
    <div className={"col-11 col-xs-10 col-sm-10 col-md-7 col-lg-6 " + styles.form}>
      <div className={styles.mainDiv}>
        <Formik
          initialValues={{
            user: '',
            password: '',
          }}
          onSubmit={(({ user, password }) => {
            dispatch(login(user, password))
          })}
          validationSchema={
            Yup.object({
              user: Yup.string()
                .required("Requerido"),
              password: Yup.string()
                .required("Requerido"),
            })
          }
        >
          {
            ({ errors }) => (
              <Form>
                <div className={styles.divLogo}>
                  <img src={logo} alt="logo" className={styles.logo} />
                </div>
                <label className={`${styles.title} form-label`}>Iniciar Sesion</label>
                <MyTextInput label='Usuario' name='user' type='text' mode={'vertical'} errors={errors} />
                <MyTextInput label='Contraseña' name='password' type='password' mode={'vertical'} errors={errors} />
                <button type="submit" className={`btn btn-primary`} style={{ width: 100 }} >
                  Entrar
                </button>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  </div>
}