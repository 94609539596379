import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { travelSummary } from '../../../store/slices/files/thunks';
import { EditFile } from '../EditFile';
import styles from './Header.module.css';
import { useDispatch } from 'react-redux';

export const Header = ({ file }) => {
  const [editFile, setEditFile] = useState(false);
  const dispatch = useDispatch();

  

  return (
    <>
      <div className="table-responsive">
        <Table className="table border text-nowrap text-md-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Agente</th>
              <th>Medio</th>
              <th>Estatus</th>
              <th>Cliente</th>
              <th>Telefono</th>
              <th>Celular</th>
              <th>Fechas Viaje</th>
              <th>Fecha limite de pago</th>
              <th>Destino</th>
              <th>Categoria</th>
              <th>Fecha</th>
              <th>Expedientes</th>
              <th>Codigo</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td>{file.agent}</td>
              <td>{file.contactWay}</td>
              <td className={`text-${file.status === "Saldo" ? "yellow" : "green"}`}>{file.status}</td>
              <td>{file.customer}</td>
              <td>{file.telephone}</td>
              <td>{file.cellphone}</td>
              <td>{file.startDate + " - " + file.endDate}</td>
              <td>{file.paydayLimit}</td>
              <td>{file.tags}</td>
              <td>{file.category}</td>
              <td>{file.requestDate}</td>
              <td>{file.files}</td>
              <td>{file.code}</td>
              <td style={{ textAlign: "center" }}>
                <i className={`fe fe-edit ${styles.edit}`} onClick={() => setEditFile(true)}></i>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <EditFile file={file} isOpen={editFile} setIsOpen={setEditFile} />
    </>
  )
}
