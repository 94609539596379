import styles from './Header.module.css'


export const Header = ({info}) => {
  return (
    <div style={{marginTop: 10, marginBottom:20}}>
      <div className={`${styles.title}`}>Categoria: {info.category}</div>
      <div className={`${styles.subTitleBlock}`}>
        <label className={`${styles.subTitle}`}>Cliente:</label><label>{info.customer}</label>
      </div>
      <div className={`${styles.subTitleBlock}`}>
        <label className={`${styles.subTitle}`}>Destino:</label><label>{info.tags}</label>
      </div>
    </div>
  )
}
