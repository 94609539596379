import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../../ui/controls/MySelect";
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { onlyIntegers, onlyIntegersDecimals } from "../../../helpers/numericFields";
import { useSelector, useDispatch } from 'react-redux';
import { addRoomLodgingGroup } from "../../../store/slices/groupFiles/thunks";
import { MyTextInput66 } from "../../ui/controls/MyTextInput66";

export const AddRoom = ({groupFile, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { lodgingGroupList } = useSelector((state) => state.groupFiles);


  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Habitacion
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            lodging: undefined,
            room: "",            
            adults: "",
            juniors: "",
            minors: "",
            infants: "",
            costPerNight: "",
            feeService: "",
            tax: "",
            others: "",
            fee: "",
          }}
          onSubmit={(({ lodging, room, adults, juniors, minors,
            infants, costPerNight, feeService, tax, others, fee}, { resetForm, setErrors }) => {

            if (!lodging) {
              return setErrors({ "lodging": "Requerido" })
            }
            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(addRoomLodgingGroup(lodging.value, room, adults, juniors,
              minors, infants, costPerNight, feeService, tax, others, fee, groupFile.id, success));
          })}
          validationSchema={
            Yup.object({
              room: Yup.string()
              .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Hospedaje:*' name="lodging" options={
                          lodgingGroupList.map(item => ({"value":item.id, "label": item.hotel}))
                        } mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("lodging", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Habitacion:*' name='room' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Adultos:' name='adults' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("adults", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Juniors:' name='juniors' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("juniors", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Menores:' name='minors' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("minors", e.target.value);
                          }} />
                      </div>
                    </div>                                   
                    <div className="col-lg-3 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Infantes:' name='infants' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("infants", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Costo x noche:' name='costPerNight' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("costPerNight", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Cuota servicio:' name='feeService' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("feeService", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Impuestos (%):' name='tax' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("tax", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Otros:' name='others' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("others", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Comision:' name='fee' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("fee", e.target.value);
                          }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
