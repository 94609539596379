import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'
import { useSelector, useDispatch } from 'react-redux'
import { SearchFileScreen } from '../components/Files/SearchFileScreen';
import { FileDetailsScreen } from '../components/File/FileDetailsScreen'
import { SearchGroupFileScreen } from '../components/GroupFile/SearchGroupFileScreen'
import { GroupFileDetailsScreen } from '../components/GroupFile/GroupFileDetailsScreen'
import { IndividualFileDetailsScreen } from '../components/GroupFile/IndividualFile/IndividualFileDetailsScreen'
import { CustomerScreen } from '../components/Catalogue/Customer/CustomerScreen'
import { AirlineScreen } from '../components/Catalogue/Airline/AirlineScreen'
import { HotelScreen } from '../components/Catalogue/Hotel/HotelScreen'
import { ServiceScreen } from '../components/Catalogue/Service/ServiceScreen'
import { WholesalerScreen } from '../components/Catalogue/Wholesaler/WholesalerScreen'
import { ContactWayScreen } from '../components/Catalogue/ContactWay/ContactWayScreen'
import { LodgingTypeScreen } from '../components/Catalogue/LodgingType/LodgingTypeScreen'
import { PublicRoute } from './PublicRoute'
import { AccessScreen } from '../components/Guest/AccessScreen'
import { GuestScreen } from '../components/Guest/GuestScreen'
import { AccessScreen as GroupAccessScreen } from '../components/Group/AccessScreen'
import { GroupScreen } from '../components/Group/GroupScreen'
import { AccessScreen as TravelAccessScreen } from '../components/Traveler/AccessScreen'
import { TravelerScreen } from '../components/Traveler/TravelerScreen'
import { UserScreen } from '../components/Catalogue/User/UserScreen'
import { AuthScreen } from '../components/Auth/AuthScreen'
import { checkLogin } from '../store/slices/auth/thunks'
import { AdvancePayment } from '../components/Payments/PendingIndividualPayments/AdvancePayment'
import { AdvancePayment as GroupAdvancePayment } from '../components/Payments/PendingGroupPayments/AdvancePayment'
import { AdvancePayment as TravelerAdvancePayment } from '../components/Payments/PendingTravelerPayments/AdvancePayment'
import { Documents } from '../components/Documents'
import { ReminderScreen } from '../components/Catalogue/Reminder/ReminderScreen'
import { RoleRoute } from './RoleRoute';
import { PublicMenuScreen } from '../components/PublicMenu/PublicMenuScreen';
import { ReligiousScreen } from '../components/Group/Ceremony/ReligiousScreen';
import { CivilScreen } from '../components/Group/Ceremony/CivilScreen';
import { ContractScreen } from '../components/Group/Contract/ContractScreen';
import { CalendarScreen } from '../components/GroupFile/Calendar/CalendarScreen';
import { SeatingPlanScreen } from '../components/Group/SeatingPlan/SeatingPlanScreen';
import { CourtshipLineScreen } from '../components/Group/CourtshipLine/CourtshipLineScreen';
import { TransportHotelScreen as TransportHotelScreen2 } from '../components/Guest/TransportHotel/TransportHotelScreen';
import { TransportHotelScreen } from '../components/Group/TransportHotel/TransportHotelScreen';
import { AirportHotel } from '../components/Requests/AirportHotel/AirportHotel';
import { HotelAirport } from '../components/Requests/HotelAirport/HotelAirport';
import { TransportChurchScreen } from '../components/Group/TransportChurch/TransportChurchScreen';
import { RepresentativeScreen } from '../components/Representatives/RepresentativeScreen';

//App
const App = React.lazy(() => import("../components/app"));
const AppGuest = React.lazy(() => import("../components/appGuest"));
const AppGroup = React.lazy(() => import("../components/appGroup"));
const AppTraveler = React.lazy(() => import("../components/appTraveler"));


const Loaderimg = () => {
  return (
    <div id="global-loader">
      <img
        src={require("../assets/images/loader.svg").default}
        className="loader-img"
        alt="Loader"
      />
    </div>
  );
};

export const AppRouter = () => {
  const { logged, role, checking } = useSelector((state) => state.auth);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkLogin());
  }, [])



  if (checking) {
    return Loaderimg()
  }


  return (
    <BrowserRouter>
      <React.Suspense fallback={Loaderimg()}>
        <Routes>
          <Route
            path={`/panel`}
            element={
              <PrivateRoute isAuthenticated={logged} role={role} >
                <App />
              </PrivateRoute>
            }
          >
            <Route index element={<SearchFileScreen />} />
            <Route
              path={`expedientes`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <SearchFileScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`agentes`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <RepresentativeScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`expediente`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <FileDetailsScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`expedienteIndividual`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <IndividualFileDetailsScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`grupos`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <SearchGroupFileScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`calendario`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <CalendarScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`expedienteGrupo`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <GroupFileDetailsScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`catalogo/clientes`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <CustomerScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`catalogo/aerolineas`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <AirlineScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`catalogo/hoteles`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <HotelScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`catalogo/mayoristas`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <WholesalerScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`catalogo/mediosDeContacto`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <ContactWayScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`catalogo/servicios`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <ServiceScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`catalogo/hospedaje`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <LodgingTypeScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`catalogo/recordatorios`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <ReminderScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`catalogo/usuarios`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <UserScreen />
                </RoleRoute>
              }
            />
            <Route
              path={`pagosPendientesInvitados`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <AdvancePayment />
                </RoleRoute>
              }
            />
            <Route
              path={`pagosPendientesGrupos`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <GroupAdvancePayment />
                </RoleRoute>
              }
            />
            <Route
              path={`pagosPendientesViajes`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <TravelerAdvancePayment />
                </RoleRoute>
              }
            />
            <Route
              path={`aeropuertoHotelPendientes`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <AirportHotel />
                </RoleRoute>
              }
            />
            <Route
              path={`hotelAeropuertoPendientes`}
              element={
                <RoleRoute allowedRoles={["DIRECTOR", "AGENTE DE VIAJES"]} role={role} >
                  <HotelAirport />
                </RoleRoute>
              }
            />
          </Route>

          <Route
            path={`/autenticacion`}
            element={
              <PublicRoute isAuthenticated={logged} >
                <AuthScreen />
              </PublicRoute>
            }
          ></Route>

          <Route
            path={`/invitado`}
            element={
              <PublicRoute isAuthenticated={logged} >
                <AccessScreen />
              </PublicRoute>
            }
          ></Route>
          <Route
            path={`/invitadoInfo`}
            element={
              <PublicRoute isAuthenticated={logged} >
                <AppGuest />
              </PublicRoute>
            }
          >
            <Route index element={<GuestScreen />} />
            <Route
              path={`transporteHotel`}
              element={
                <PublicRoute isAuthenticated={logged} >
                  <TransportHotelScreen2 />
                </PublicRoute>
              }
            />
          </Route>

          <Route
            path={`/grupo`}
            element={
              <PublicRoute isAuthenticated={logged} >
                <GroupAccessScreen />
              </PublicRoute>
            }
          ></Route>
          <Route
            path={`/grupoInfo`}
            element={
              <PublicRoute isAuthenticated={logged} >
                <AppGroup />
              </PublicRoute>
            }
          >
            <Route index element={<GroupScreen />} />
            <Route
              path={`ceremoniaCatolica`}
              element={
                <PublicRoute isAuthenticated={logged} >
                  <ReligiousScreen />
                </PublicRoute>
              }
            />
            <Route
              path={`ceremoniaCivil`}
              element={
                <PublicRoute isAuthenticated={logged} >
                  <CivilScreen />
                </PublicRoute>
              }
            />
            <Route
              path={`contrato`}
              element={
                <PublicRoute isAuthenticated={logged} >
                  <ContractScreen />
                </PublicRoute>
              }
            />
            <Route
              path={`lineaDeCortejo`}
              element={
                <PublicRoute isAuthenticated={logged} >
                  <CourtshipLineScreen />
                </PublicRoute>
              }
            />
            <Route
              path={`planDeMesas`}
              element={
                <PublicRoute isAuthenticated={logged} >
                  <SeatingPlanScreen />
                </PublicRoute>
              }
            />
            <Route
              path={`transporteHotel`}
              element={
                <PublicRoute isAuthenticated={logged} >
                  <TransportHotelScreen />
                </PublicRoute>
              }
            />
            <Route
              path={`transporteIglesia`}
              element={
                <PublicRoute isAuthenticated={logged} >
                  <TransportChurchScreen />
                </PublicRoute>
              }
            />

          </Route>

          <Route
            path={`/viaje`}
            element={
              <PublicRoute isAuthenticated={logged} >
                <TravelAccessScreen />
              </PublicRoute>
            }
          ></Route>
          <Route
            path={`/viajeInfo`}
            element={
              <PublicRoute isAuthenticated={logged} >
                <AppTraveler />
              </PublicRoute>
            }
          >
            <Route index element={<TravelerScreen />} />
          </Route>

          <Route
            path={`/`}
            element={
              <PublicRoute isAuthenticated={logged} >
                <PublicMenuScreen />
              </PublicRoute>
            }
          ></Route>

          <Route
            path={`/*`}
            element={
              <div>Pagina no valida</div>
            }
          ></Route>
          <Route
            path={`/documents`}
            element={
              <Documents />
            }
          >
          </Route>
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  )
}
