import { useState } from "react";
import { Table } from "react-bootstrap";
import styles from './CourtshipLineDetails.module.css'
import { useDispatch } from 'react-redux';
import { EditElement } from "./EditElement";
import { moveElementToCourtshipLine } from "../../../store/slices/group/thunks";

export const CourtshipLineDetails = ({ courtshipLine, code, idGroupFile }) => {
  const [editElement, setEditElement] = useState(false);
  const [elementSelected, setElementSelected] = useState({});
  const dispatch = useDispatch();

  const edit2 = (element) => {
    setEditElement(true);
    setElementSelected(element);
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap mg-b-0">
          <thead>
            <tr>
              <th>+</th>
              <th>-</th>
              <th>Nombre</th>
              <th>Persona 1</th>
              <th>Telefono 1</th>
              <th>Persona 2</th>
              <th>Telefono 2</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {
              courtshipLine.map((element, index) => (
                <tr key={element.id}>
                  <td>{index != 0 &&
                    <i className={`fa fa-arrow-up ${styles.move}`} onClick={() => dispatch(moveElementToCourtshipLine(idGroupFile, element.id, "Up", code))}></i>}
                  </td>
                  <td>{index + 1 != courtshipLine.length &&
                    <i className={`fa fa-arrow-down ${styles.move}`} onClick={() => dispatch(moveElementToCourtshipLine(idGroupFile, element.id, "Down", code))}></i>}
                  </td>
                  <td>{element.name}</td>
                  <td>{element.person1}</td>
                  <td>{element.telephone1}</td>
                  <td>{element.person2}</td>
                  <td>{element.telephone2}</td>
                  <td style={{ textAlign: "center" }}>
                    <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(element)}></i>
                  </td>
                </tr>))
            }
          </tbody>
        </Table>
      </div>

      <div className={styles.draws}>
        {
          courtshipLine.map((element, index) => (
            <div key={element.id}>
              <div className={`${styles.elementName2} mb-5 ${index % 2 == 0 ? styles.textLeft : styles.textRight}`}>
                <span className={`${styles.elementNameText}`}>
                  {element.name}&nbsp;&nbsp;
                </span>
              </div>
              <div className={`row ${styles.element}`}>
                <div className="col-2">{element.person1}</div>
                <div className="col-3">
                  <img className={styles.imageDraw} src={require("../../../assets/images/draws/avatar.png")} alt="avatar" />
                </div>
                <div className="col-3">
                  <img className={styles.imageDraw} src={require("../../../assets/images/draws/avatar.png")} alt="avatar" />
                </div>
                <div className="col-2">{element.person2}</div>
              </div>
              <hr />
            </div>
          ))
        }
      </div>
      <EditElement element={elementSelected} code={code}
        isOpen={editElement} setIsOpen={setEditElement} />
    </div>
  )
}
