import React, { useState } from 'react'
import { Table } from "react-bootstrap";
import { currencyFormat } from '../../../../helpers/numericFields';

export const MoneyFlow = ({ masterSale }) => {

  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ fontSize: 20 }}>Flujo de dinero</div>
      <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
        <div className="table-responsive">
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>Total</th>
                <th>Anticipos</th>
                <th>Pagos a mayoristas</th>
                <th>Disponibles</th>
                <th>Saldo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {currencyFormat(
                    masterSale.groupRooms.reduce((a, b) => a += (b.publicPriceTotal), 0) +
                    masterSale.services.reduce((a, b) => a += (b.publicPriceTotal), 0) +
                    masterSale.air.reduce((a, b) => a += (b.publicPriceTotal), 0) +
                    masterSale.lodging.reduce((a, b) => a += (b.publicPriceTotal), 0) +
                    masterSale.cruise.reduce((a, b) => a += (b.publicPriceTotal), 0) +
                    masterSale.service.reduce((a, b) => a += (b.publicPriceTotal), 0)
                  )}
                </td>
                <td>
                  {currencyFormat(
                    masterSale.advancePayments.reduce((a, b) => (b.approved === 1) ? a += b.amount : a, 0))}
                </td>
                <td>{
                  currencyFormat(
                    masterSale.paymentsToWholesalerServices.reduce((a, b) => a += b.amount, 0) +
                    masterSale.paymentsToWholesalerAir.reduce((a, b) => a += b.amount, 0) +
                    masterSale.paymentsToWholesalerLodging.reduce((a, b) => a += b.amount, 0) +
                    masterSale.paymentsToWholesalerCruise.reduce((a, b) => a += b.amount, 0) +
                    masterSale.paymentsToWholesalerService.reduce((a, b) => a += b.amount, 0)
                  )}
                </td>
                <td>{
                  currencyFormat(
                    (masterSale.advancePayments.reduce((a, b) => (b.approved === 1) ? a += b.amount : a, 0) + 0) -
                    (
                      masterSale.paymentsToWholesalerServices.reduce((a, b) => a += b.amount, 0) +
                      masterSale.paymentsToWholesalerAir.reduce((a, b) => a += b.amount, 0) +
                      masterSale.paymentsToWholesalerLodging.reduce((a, b) => a += b.amount, 0) +
                      masterSale.paymentsToWholesalerCruise.reduce((a, b) => a += b.amount, 0) +
                      masterSale.paymentsToWholesalerService.reduce((a, b) => a += b.amount, 0)
                    )
                  )}
                </td>
                <td>{
                  currencyFormat(
                    masterSale.advancePayments.reduce((a, b) => (b.approved === 1) ? a += b.amount : a, 0) -
                    (
                      masterSale.groupRooms.reduce((a, b) => a += (b.publicPriceTotal), 0) +
                      masterSale.services.reduce((a, b) => a += (b.publicPriceTotal), 0) +
                      masterSale.air.reduce((a, b) => a += (b.publicPriceTotal), 0) +
                      masterSale.lodging.reduce((a, b) => a += (b.publicPriceTotal), 0) +
                      masterSale.cruise.reduce((a, b) => a += (b.publicPriceTotal), 0) +
                      masterSale.service.reduce((a, b) => a += (b.publicPriceTotal), 0)
                    )
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  )
}
