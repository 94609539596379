import { Button, Modal, Row } from "react-bootstrap";
import placeHolder from '../../../images/placeholder-image.png'

export const ModalImage = ({ image, isOpen, setIsOpen }) => {

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Imagenes
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Modal.Body>
        <img 
          src={process.env.REACT_APP_URL_IMAGES +'/'+ image}
          onError={(e)=> e.target.src=placeHolder}
          alt={"carousel"} />
        </Modal.Body>

        <Modal.Footer>
          <div style={{ width: "100%" }}>
            <Button variant="primary"  onClick={() => setIsOpen(false)} style={{ float: "right" }}>
              Cerrar
            </Button>
          </div>
        </Modal.Footer>


      </Modal>
    </>
  )
}
