import { useEffect, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { MySelect } from "../../ui/controls/MySelect";
import { useSelector, useDispatch } from 'react-redux';
import { editUser, getRolesList } from "../../../store/slices/catalogue/thunks";
import { MyCheckBox2 } from "../../ui/controls/MyCheckBox2";
import { Image_Type } from "../../../const/Image"
import { MyFileInput } from "../../ui/controls/MyFileInput";
import Swal from "sweetalert2";
import { onlyIntegersDecimals } from "../../../helpers/numericFields";

export const EditUser = ({ item, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { rolesList } = useSelector((state) => state.catalogue);

  useEffect(() => {
    dispatch(getRolesList());
  }, [])

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Usuario
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            name: item.name,
            userName: item.userName,
            password: '',
            idRole: { "value": item.idRole, "label": item.role },
            image: undefined,
            status: item.status === 1 ? true : false,
            fee: item.fee,
          }}
          onSubmit={(({ name, userName, password, idRole, image2, fee, status }, { resetForm, setErrors }) => {
            if (!idRole) {
              return setErrors({ "idRole": "Requerido" })
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(editUser(item.id, name, userName, password, idRole.value, image2, fee,
              (status || status === 1 ? 1 : 0), success));
          })}
          validationSchema={
            Yup.object({
              name: Yup.string()
                .required("Requerido"),
              userName: Yup.string()
                .required("Requerido")
                .min(4, "Longitud minima de 4 caracteres"),
              password: Yup.string()
                .min(8, "Longitud minima de 8 caracteres"),
              fee: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Nombre:*' name='name' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Usuario:*' name='userName' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Contraseña:' name='password' type='password' mode="horizontal" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Rol:*' name="idRole" options={rolesList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("idRole", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <MyFileInput label='Imagen:' name='image' mode='horizontal'
                        onChange={(e) => {
                          if (e.target.files[0]) {
                            if (Image_Type.indexOf(e.target.files[0].type) === -1) {
                              setFieldValue("image2", undefined)
                              return Swal.fire("Catalogos", "Formato no valido", "error");
                            }
                            if (e.target.files[0].size > 2000000) {
                              setFieldValue("image2", undefined)
                              return Swal.fire("Catalogos", "Solo se permiten imagenes de 2MB max", "error");
                            }
                            setFieldValue("image2", e.currentTarget.files[0])
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Comision %:*' name='fee' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e) || parseFloat(e.target.value) < 0 || parseFloat(e.target.value) > 100) {
                              return
                            }
                            setFieldValue("fee", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12" >
                      <div className=" row mb-4">
                        <MyCheckBox2 label="Estatus:" name="status" mode='horizontal' style={{ fontSize: 20, marginTop: 8 }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  );
}
