import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { currencyFormat } from "../../../../helpers/numericFields";
import styles from './MasterSaleDetails.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { downloadPayments, openCloseMasterSale } from '../../../../store/slices/individualFiles/thunks';
import { AddService } from './AddService';
import { EditService } from "./EditService";
import { getServicesList } from "../../../../store/slices/general/thunks";
import { EditRoom } from "./EditRoom";
import { AddRoom } from "./AddRoom";

export const MasterSaleDetails = ({ masterSale, individualFile }) => {
  const { role } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [addRoom, setAddRoom] = useState(false);
  const [addService, setAddService] = useState(false);
  const [editService, setEditService] = useState(false);
  const [editRoom, setEditRoom] = useState(false);
  const [serviceSelected, setServiceSelected] = useState({});
  const [roomSelected, setRoomSelected] = useState({});

  useEffect(() => {
    dispatch(getServicesList())
  }, [])

  const openCloseMasterSale2 = (status) => {
    dispatch(openCloseMasterSale(masterSale.id, status, individualFile.id))
  }

  const editService2 = (service) => {
    setEditService(true);
    setServiceSelected(service)
  }

  const editRoom2 = (service) => {
    setEditRoom(true);
    setRoomSelected(service)
  }
  
  const downloadPayments2 = () => {
    dispatch(downloadPayments(individualFile.id))
  }

  return (
    <>
      {
        masterSale.isOpen
          ? <>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
              <button className="btn btn-light btn-icon"
                onClick={() => setAddRoom(true)} >Agregar Partida Hospedaje</button>
              <button className="btn btn-light btn-icon"
                onClick={() => setAddService(true)} >Agregar Partida Servicio</button>
              <AddRoom masterSale={masterSale} individualFile={individualFile} isOpen={addRoom} setIsOpen={setAddRoom} />
              <AddService masterSale={masterSale} individualFile={individualFile} isOpen={addService} setIsOpen={setAddService} />
            </div>
          </>
          : <></>
      }
      <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
        <div className="table-responsive">
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>Concepto</th>
                <th>Cantidad</th>
                <th>Descripcion</th>
                <th>Fechas</th>
                <th>Mayorista</th>
                <th>Boleto</th>
                <th>Reserva</th>
                <th>Base</th>                
                <th>Cuota Servicio</th>                
                <th>Impuestos</th>
                <th>Otros</th>
                <th>Comision</th>
                <th>Tarifa Publica</th>
                <th>Tarifa Neta</th>
                {
                  masterSale.isOpen === 1 && <th>Editar</th>
                }
              </tr>
            </thead>
            <tbody>
              {
                masterSale.groupRooms.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>{item.quantity}</td>
                    <td>{item.hotel} - {item.room}</td>
                    <td>{item.checkInDate}<br />{item.checkOutDate}</td>
                    <td>{item.wholesaler}</td>
                    <td>{item.locator}</td>
                    <td>{item.reserve}</td>
                    <td>{currencyFormat(item.costPerNightTotal)}</td>                    
                    <td>{currencyFormat(item.feeServiceTotal)}</td>                    
                    <td>{item.tax + " %"}</td>                    
                    <td>{currencyFormat(item.othersTotal)}</td>
                    <td>{currencyFormat(item.feeTotal)}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td>{currencyFormat(item.netPriceTotal)}</td>
                    {
                      masterSale.isOpen === 1
                      && <td style={{ textAlign: "center" }}>
                        <i className={`fe fe-edit ${styles.edit}`} onClick={() => editRoom2(item)}></i>
                      </td>                        
                    }
                  </tr>))
              }
              {
                masterSale.services.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>{item.quantity}</td>
                    <td>{item.service}</td>
                    <td>{item.startDate}<br />{item.endDate}</td>
                    <td>{item.wholesaler}</td>
                    <td>{item.ticket}</td>
                    <td>{item.reserve}</td>
                    <td>{currencyFormat(item.costPerUnitTotal)}</td>
                    <td>{currencyFormat(item.feeServiceTotal)}</td>
                    <td>{item.tax + " %"}</td>
                    <td>{currencyFormat(item.othersTotal)}</td>
                    <td>{currencyFormat(item.feeTotal)}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td>{currencyFormat(item.netPriceTotal)}</td>
                    {
                      masterSale.isOpen === 1
                      && <td style={{ textAlign: "center" }}>
                        <i className={`fe fe-edit ${styles.edit}`} onClick={() => editService2(item)}></i>
                      </td>                        
                    }
                  </tr>))
              }
              {
                masterSale.air.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.airline}</td>
                    <td>{item.departureDate}<br />{item.returnDate}</td>
                    <td>{item.wholesaler}</td>
                    <td>{item.ticket}</td>
                    <td>{item.reserve}</td>
                    <td>{currencyFormat(item.rate)}</td>                    
                    <td>{currencyFormat(item.feeService)}</td>                    
                    <td>{currencyFormat(item.tax)}</td>
                    <td>{currencyFormat(item.others)}</td>
                    <td>{currencyFormat(item.fee)}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td>{currencyFormat(item.netPriceTotal)}</td>
                    <td></td>
                  </tr>))
              }
              {
                masterSale.lodging.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.hotel} - {item.room}</td>
                    <td>{item.checkInDate}<br />{item.checkOutDate}</td>
                    <td>{item.wholesaler}</td>
                    <td>{item.ticket}</td>
                    <td>{item.reserve}</td>
                    <td>{currencyFormat(item.rate)}</td>                                        
                    <td>{currencyFormat(item.feeService)}</td>                                        
                    <td>{currencyFormat(item.tax)}</td>
                    <td>{currencyFormat(item.others)}</td>
                    <td>{currencyFormat(item.publicPriceTotal - item.netPriceTotal)}</td>                    
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td>{currencyFormat(item.netPriceTotal)}</td>
                    <td></td>
                  </tr>))
              }
              {
                masterSale.cruise.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.company} - {item.cabin}</td>
                    <td>{item.departureDate}<br />{item.returnDate}</td>
                    <td>{item.wholesaler}</td>
                    <td>{item.ticket}</td>
                    <td>{item.reserve}</td>
                    <td>{currencyFormat(item.rate)}</td>                    
                    <td>{currencyFormat(item.feeService)}</td>              
                    <td>{currencyFormat(item.tax)}</td>
                    <td>{currencyFormat(item.others)}</td>
                    <td>{currencyFormat(item.publicPriceTotal - item.netPriceTotal)}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td>{currencyFormat(item.netPriceTotal)}</td>
                    <td></td>
                  </tr>))
              }
              {
                masterSale.service.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.service}</td>
                    <td>{item.startDate}<br />{item.endDate}</td>
                    <td>{item.wholesaler}</td>
                    <td>{item.ticket}</td>
                    <td>{item.reserve}</td>
                    <td>{currencyFormat(item.rate)}</td>                    
                    <td>{currencyFormat(item.feeService)}</td>                                        
                    <td>{currencyFormat(item.tax)}</td>
                    <td>{currencyFormat(item.others)}</td>
                    <td>{currencyFormat(item.rate - item.netPriceTotal)}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td>{currencyFormat(item.netPriceTotal)}</td>
                    <td></td>
                  </tr>))
              }
            </tbody>
          </Table>
        </div>
        <EditRoom room={roomSelected} individualFile={individualFile} isOpen={editRoom} setIsOpen={setEditRoom} />
        <EditService service={serviceSelected} individualFile={individualFile} isOpen={editService} setIsOpen={setEditService} />
        <div className={styles.total}>Total: {
          currencyFormat(
            masterSale.groupRooms.reduce((a, b) => a += (b.publicPriceTotal), 0) +
            masterSale.services.reduce((a, b) => a += (b.publicPriceTotal), 0) +
            masterSale.air.reduce((a, b) => a += (b.publicPriceTotal), 0) +
            masterSale.lodging.reduce((a, b) => a += (b.publicPriceTotal), 0) +
            masterSale.cruise.reduce((a, b) => a += (b.publicPriceTotal), 0) +
            masterSale.service.reduce((a, b) => a += (b.publicPriceTotal), 0)
          )}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button className="btn btn-primary btn-icon"
                onClick={() => downloadPayments2()} >Descargar pagos</button>        
        {
          masterSale.isOpen
            ? <button className="btn btn-danger btn-icon"
                onClick={() => openCloseMasterSale2(false)} >Cerrar Venta</button>            
            : (
              role === "DIRECTOR" 
              ? <button className="btn btn-danger btn-icon"
                  onClick={() => openCloseMasterSale2(true)} >Abrir Venta</button>
              : <></>
            )
        }
      </div>
    </>
  )
}
