import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { roomingListDetails } from '../../store/slices/group/thunks';

export const Romming = ({ hotels, code }) => {
  const dispatch = useDispatch();

  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ fontSize: 20 }}>Romming List</div>
      {
        hotels.map(hotel => (
          <button key={hotel.idHotel} className={`btn text-white btn-primary`} style={{ marginTop: 20, marginBottom: 20 }}
            onClick={() => dispatch(roomingListDetails(code, hotel.idHotel))} >
            Descargar - {hotel.name}
          </button>
        ))
      }

    </div>
  )
}
