import { useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { currencyFormat } from "../../../helpers/numericFields";
import styles from './AgencyPaymentSchemeDetails.module.css'
import { EditAgencyPaymentScheme } from "./EditAgencyPaymentScheme";

export const AgencyPaymentSchemeDetails = ({ groupFile }) => {
  const { role } = useSelector((state) => state.auth);
  const { paymentScheme } = useSelector((state) => state.groupFiles);
  const [editAgencyPaymentScheme2, setEditAgencyPaymentScheme2] = useState(false);
  const [agencyPaymentSchemeSelected, setAgencyPaymentSchemeSelected] = useState({});


  const edit2 = (payment) => {
    setEditAgencyPaymentScheme2(true);
    setAgencyPaymentSchemeSelected(payment)
  }


  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap mg-b-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Tipo de pago</th>
              <th>Cantidad</th>
              <th>Fecha</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {
              paymentScheme.map((payment, index) => (
                <tr key={payment.id}>
                  <td>{index + 1}</td>
                  <td>{payment.topic}</td>
                  <td>{currencyFormat(payment.amount)}</td>
                  <td className="break-word ">{payment.date}</td>
                  {
                    role === "DIRECTOR"
                    && <td style={{ textAlign: "center" }}>
                      <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(payment)}></i>
                    </td>
                  }
                </tr>))
            }
          </tbody>
        </Table>
      </div>

      <EditAgencyPaymentScheme payment={agencyPaymentSchemeSelected} groupFile={groupFile}
        isOpen={editAgencyPaymentScheme2} setIsOpen={setEditAgencyPaymentScheme2} />
    </div>
  )
}
