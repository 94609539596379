import { useState } from 'react';
import * as colors from '../../../const/colors'
import { EditLodging } from './EditLodging';
import styles from './LodgingDetails.module.css'

export const LodgingDetails = ({ lodging, groupFile }) => {
  const [editLodging, setEditLodging] = useState(false);
  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className={`${styles.title} ${styles.edit}`} style={{ color: colors.title, marginLeft: 5 }}
        onClick={() => setEditLodging(true)}>{lodging.hotel}</div>
      <div style={{ padding: 10 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Entrada: </div>
          <div className={`${styles.segment}`} style={{ color: colors.title, marginLeft: 5 }}>{lodging.checkInDate}</div>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title, marginLeft: 20 }}>Salida: </div>
          <div className={`${styles.segment}`} style={{ color: colors.title, marginLeft: 5 }}>{lodging.checkOutDate}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Misma Tarifa: </div>
          <div className={`${styles.segment}`} style={{ color: colors.title, marginLeft: 5, marginRight: 3 }}>{lodging.daysBefore}</div>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}> dias antes </div>
          <div className={`${styles.segment}`} style={{ color: colors.title, marginLeft: 5, marginRight: 3 }}>{lodging.daysAfter}</div>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>dias despues</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Tipo de hospedaje:</div>
          <div className={`${styles.segment}`} style={{ color: colors.title, marginLeft: 5, }}>{lodging.lodgingType}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Notas:</div>
          <div className={`${styles.segment}`} style={{ color: colors.title, marginLeft: 5, }}>{lodging.notes}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Ubicacion:</div>
          <div className={`${styles.segment}`} style={{ color: colors.title, marginLeft: 5, }}>{lodging.address}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Telefono:</div>
          <div className={`${styles.segment}`} style={{ color: colors.title, marginLeft: 5, }}>{lodging.telephone}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Email:</div>
          <div className={`${styles.segment}`} style={{ color: colors.title, marginLeft: 5, }}>{lodging.email}</div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Mayorista:</div>
          <div className={`${styles.segment}`} style={{ color: colors.title, marginLeft: 5, }}>{lodging.wholesaler}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Telefono:</div>
          <div className={`${styles.segment}`} style={{ color: colors.title, marginLeft: 5, }}>{lodging.wholesalerTelephone}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={`${styles.title} ${styles.segment}`} style={{ color: colors.title }}>Email:</div>
          <div className={`${styles.segment}`} style={{ color: colors.title, marginLeft: 5, }}>{lodging.wholesalerEmail}</div>
        </div>
      </div>
      <EditLodging lodging={lodging} groupFile={groupFile} isOpen={editLodging} setIsOpen={setEditLodging} />
    </div>
  )
}
