import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../ui/controls/MySelect";
import { MyTextInput } from "../ui/controls/MyTextInput";
import { MyDatePicker } from "../ui/controls/MyDatePicker";
import { MyTextArea2 } from "../ui/controls/MyTextArea2";
import { onlyIntegersDecimals } from "../../helpers/numericFields";
import { useSelector, useDispatch } from 'react-redux';
import { deleteAirQuote, editAirQuote } from "../../store/slices/quotes/thunks";

export const EditAir = ({ air, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { currenciesList, airlinesList, wholesalersList } = useSelector((state) => state.general);

  const delete2 = () => {
    const success = () => {
      setIsOpen(false)
    }
    dispatch(deleteAirQuote(air.id, air.idQuote, success));
  }

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Avion En Cotizacion
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            departureReturnDate: [air.departureDate, air.returnDate],
            airline: { value: air.idAirline, label: air.airline },
            wholesaler: air.wholesaler ? { value: air.idWholesaler, label: air.wholesaler } : undefined,
            outboundItinerary: air.outboundItinerary || "",
            returnItinerary: air.returnItinerary || "",
            rate: air.rate,
            fee: air.fee || "",
            currency: { value: air.idCurrency, label: air.currency },
            notes: air.notes || "",
          }}
          onSubmit={(({ departureReturnDate, airline, wholesaler, outboundItinerary, returnItinerary, rate, fee,
            currency, notes }, { resetForm, setErrors }) => {

            let departureDate;
            if (departureReturnDate[0].year) {
              departureDate = departureReturnDate[0].year + "-" + (departureReturnDate[0].monthIndex + 1) + "-" + departureReturnDate[0].day
            } else {
              const temp = departureReturnDate[0].split("-")
              departureDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }
            let returnDate;
            if (departureReturnDate[1].year) {
              returnDate = departureReturnDate[1].year + "-" + (departureReturnDate[1].monthIndex + 1) + "-" + departureReturnDate[1].day
            } else {
              const temp = departureReturnDate[1].split("-")
              returnDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }

            if (!departureReturnDate || departureReturnDate.length !== 2) {
              return setErrors({ "departureReturnDate": "Requerido" })
            }
            if (!airline) {
              return setErrors({ "airline": "Requerido" })
            }
            if (!currency) {
              return setErrors({ "currency": "Requerido" })
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(editAirQuote(air.id, departureDate, returnDate, airline.value, wholesaler ? wholesaler.value : "",
              outboundItinerary, returnItinerary, rate, fee, currency.value, notes, air.idQuote, success));
          })}
          validationSchema={
            Yup.object({
              rate: Yup.string()
                .required("Requerido")
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Ida y Vuelta: *' name="departureReturnDate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                          range
                          // value={[quote.startDate, quote.endDate]}
                          onChange={e => {
                            setFieldValue("departureReturnDate", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-0"></div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Aerolinea:*' name="airline" options={airlinesList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("airline", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Mayorista:' name="wholesaler" options={[{value:"",label:""},...wholesalersList]} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("wholesaler", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Itinerario Ida:' name='outboundItinerary' mode='horizontal' rows={2} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Itinerario Regreso:' name='returnItinerary' mode='horizontal' rows={2} />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Tarifa: *' name='rate' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("rate", e.target.value);
                          }} />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Cuota:' name='fee' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("fee", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Divisa *' name="currency" options={currenciesList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("idCurrency", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={2} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <div style={{ width: "100%" }}>
                    <Button variant="danger" type="button" style={{ float: "left" }} onClick={delete2}>
                      Eliminar
                    </Button>
                    <Button variant="primary" type="submit" style={{ float: "right" }}>
                      Guardar Informacion
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
