import { useField, ErrorMessage } from 'formik';

export const MyCheckBox2 = ({ label, ...props }) => {

  const [field] = useField({ ...props, type: 'checkbox' });

  return (
    <div className="mb-3 row">
      <div className="form-check">
        <label className="col-form-label col-4" htmlFor={label}>{label}</label>
        <input className="form-check-input " type="checkbox" id={label} {...field} {...props} />
      </div>
    </div>
  )
}