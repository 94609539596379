import { useState } from "react";
import { Table } from "react-bootstrap";
import { currencyFormat } from "../../../helpers/numericFields";
import { EditRoom } from "./EditRoom";
import styles from './RoomDetails.module.css'

export const RoomDetails = ({ lodging, groupFile }) => {
  const [editRoom, setEditRoom] = useState(false)
  const [roomSelected, setRoomSelected] = useState({});

  const editRoom2 = (room) => {
    setEditRoom(true);
    setRoomSelected(room)
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className={`${styles.title}`}>Hospedaje: {lodging.name}</div>
      <div className="table-responsive">
        <Table className="table border text-nowrap text-md-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Habitacion</th>
              <th>Costo x noche</th>
              <th>Cuota servicio</th>
              <th>Impuestos</th>
              <th>Otros</th>
              <th>Comision</th>
              <th>Adultos</th>
              <th>Juniors</th>
              <th>Menores</th>
              <th>Infantes</th>
              <th>Tarifa Publica</th>
              <th>Tarifa Neta</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {
              lodging.rooms.map(item => (
                <tr key={item.id}>
                  <td>{item.room}</td>
                  <td>{currencyFormat(item.costPerNight)}</td>
                  <td>{currencyFormat(item.feeService)}</td>
                  <td>{item.tax + " %"}</td>
                  <td>{currencyFormat(item.others)}</td>
                  <td>{currencyFormat(item.fee)}</td>
                  <td>{item.adults}</td>
                  <td>{item.juniors}</td>
                  <td>{item.minors}</td>
                  <td>{item.infants}</td>
                  <td>{currencyFormat(item.publicPrice)}</td>
                  <td>{currencyFormat(item.netPrice)}</td>                  
                  <td style={{ textAlign: "center" }}>
                    <i className={`fe fe-edit ${styles.edit}`} onClick={() => editRoom2(item)}></i>
                  </td>
                </tr>))
            }
          </tbody>
        </Table>
      </div>
      <EditRoom room={roomSelected} lodging={lodging} groupFile={groupFile} isOpen={editRoom} setIsOpen={setEditRoom} />
    </div>
  )
}
