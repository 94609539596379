import { viajesApi } from "../../../api/viajesApi";
import { setAirlinesList, setCurrenciesList, setHotelsList, setPaymentMethodList, 
  setPaymentWayList, setServicesList, setWholesalersList, setCustomersList, setFilterMonthList, setContactWayList, 
  setCategoriesList, setLodgingTypeList, setUsersList, setRepresentativesList
} from "./generalSlice";

export const getCurrenciesList = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/general/currencies");
      dispatch(setCurrenciesList(resp.data.results.map(item => ({ "value": item.id, "label": item.name }))));
    } catch (error) {

    }
  }
}

export const getAirlinesList = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/general/airlines");
      dispatch(setAirlinesList(resp.data.results.map(item => ({ "value": item.id, "label": item.name }))));
    } catch (error) {

    }
  }
}

export const getWholesalersList = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/general/wholesalers");
      dispatch(setWholesalersList(resp.data.results.map(item => ({ "value": item.id, "label": item.name }))));
    } catch (error) {

    }
  }
}

export const getHotelsList = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/general/hotels");
      dispatch(setHotelsList(resp.data.results.map(item => ({ "value": item.id, "label": item.name }))));
    } catch (error) {

    }
  }
}

export const getServicesList = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/general/services");
      dispatch(setServicesList(resp.data.results.map(item => ({ "value": item.id, "label": item.name }))));
    } catch (error) {

    }
  }
}

export const getPaymentMethodList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setPaymentWayList([]));
      const resp = await viajesApi.get("/general/paymentMethodList");
      dispatch(setPaymentMethodList(resp.data.results.map(item => ({ "value": item.id, "label": item.name }))));
    } catch (error) {

    }
  }
}

export const getPaymentWayList = (idPaymentMethod) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setPaymentWayList([]));
      const resp = await viajesApi.get("/general/paymentWayList", {
        params: { idPaymentMethod }
      });
      dispatch(setPaymentWayList(resp.data.results.map(item => ({ "value": item.id, "label": item.name }))));
    } catch (error) {

    }
  }
}

export const getCustomersList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setCustomersList([]));
      const resp = await viajesApi.get("/general/customers");
      dispatch(setCustomersList(resp.data.results.map(item => ({ "value": item.id, "label": item.name }))));
    } catch (error) {

    }
  }
}

export const getFilterMonth = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/general/filterMonths");
      dispatch(setFilterMonthList(resp.data.results.map(item => ({ "value": item, "label": item }))));
    } catch (error) {

    }
  }
}

export const getContactWayList = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/general/contactWayList");
      dispatch(setContactWayList(resp.data.results.map(item => ({ "value": item.id, "label": item.name }))));
    } catch (error) {

    }
  }
}

export const getCategoriesList = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/general/categoriesList");
      dispatch(setCategoriesList(resp.data.results.map(item => ({ "value": item.id, "label": item.name }))));
    } catch (error) {

    }
  }
}

export const getLodgingTypeList = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/general/lodgingTypeList");
      dispatch(setLodgingTypeList(resp.data.results.map(item => ({ "value": item.id, "label": item.name }))));
    } catch (error) {

    }
  }
}

export const getUsersList = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/general/usersList");
      dispatch(setUsersList(resp.data.results.map(item => ({ "value": item.id, "label": item.name }))));
    } catch (error) {

    }
  }
}

export const getRepresentativesList = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/general/representativesList");
      dispatch(setRepresentativesList(resp.data.results.map(item => ({ "value": item.id, "label": item.name }))));
    } catch (error) {
      
    }
  }
}