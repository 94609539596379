import { GroupSalesDetails } from './GroupSalesDetails';

export const GroupSales = ({masterSale}) => {
  return (
    <div style={{marginTop:20}}>
      <div style={{ fontSize: 20 }}>Venta de grupo</div>
      <GroupSalesDetails masterSale={masterSale} />
    </div>
  )
}
