import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../ui/controls/MySelect";
import { MyTextInput } from "../ui/controls/MyTextInput";
import { MyDatePicker } from "../ui/controls/MyDatePicker";
import { MyTextArea2 } from "../ui/controls/MyTextArea2";
import { useSelector, useDispatch } from 'react-redux';
import { addServiceQuote } from "../../store/slices/quotes/thunks";
import { onlyIntegersDecimals } from "../../helpers/numericFields";
import { MyTextInput66 } from "../ui/controls/MyTextInput66";


export const AddService = ({ quote, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { currenciesList, wholesalersList } = useSelector((state) => state.general);

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Servicio A Cotizacion
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            startEndDate: [quote.startDate, quote.endDate],
            service: '',
            wholesaler: undefined,
            details: '',
            rate: '',
            netPrice: '',
            currency: { value: 1, label: "MXN" },
            notes: '',
          }}
          onSubmit={(({ startEndDate, service, wholesaler, details, rate, netPrice, currency, notes },
            { resetForm, setErrors }) => {

            let startDate;
            if (startEndDate[0].year) {
              startDate = startEndDate[0].year + "-" + (startEndDate[0].monthIndex + 1) + "-" + startEndDate[0].day
            } else {
              const temp = startEndDate[0].split("-")
              startDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }
            let endDate;
            if (startEndDate[1].year) {
              endDate = startEndDate[1].year + "-" + (startEndDate[1].monthIndex + 1) + "-" + startEndDate[1].day
            } else {
              const temp = startEndDate[1].split("-")
              endDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }

            if (!startEndDate || startEndDate.length !== 2) {
              return setErrors({ "startEndDate": "Requerido" })
            }
            if (!wholesaler) {
              return setErrors({ "wholesaler": "Requerido" })
            }
            if (!currency) {
              return setErrors({ "currency": "Requerido" })
            }


            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(addServiceQuote(quote.id, startDate, endDate, service, wholesaler.value, details, rate,
              netPrice, currency.value, notes, success));
          })}
          validationSchema={
            Yup.object({
              service: Yup.string()
                .required("Requerido"),
              details: Yup.string()
                .required("Requerido"),
              rate: Yup.string()
                .required("Requerido"),
              netPrice: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Inicio y Fin:*' name="startEndDate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                          range
                          onChange={e => {
                            setFieldValue("startEndDate", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-0"></div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Servicio:*' name='service' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Mayorista:*' name="wholesaler" options={wholesalersList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("wholesaler", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Detalles:*' name='details' mode='horizontal' rows={2} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Tarifa Publica:*' name='rate' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("rate", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Tarifa Neta:*' name='netPrice' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("netPrice", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Divisa:*' name="currency" options={currenciesList} mode='horizontal'
                          defaultValue={{ value: 1, label: "MXN" }}
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("currency", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={2} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
