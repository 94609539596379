import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from "react-router-dom"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useSelector } from 'react-redux';
import { currencyFormat } from '../../helpers/numericFields';

export const SearchTable = () => {
  const { files } = useSelector((state) => state.representative);
  const columns = [
    {
      name: "#Exp",
      selector: row => [row.idFile],
      sortable: true, 
      width:"85px",
      cell: row => <span className="text-center w-100">
        <OverlayTrigger placement="top" overlay={<Tooltip >Ver Expediente</Tooltip>}>
          <Link to={`/panel/expediente?id=${row.idFile}`} 
            className="btn btn-primary btn-sm rounded-11 me-2" ><span className="fs-15 fw-semibold text-center">{row.idFile}</span></Link>
        </OverlayTrigger>
      </span>
    },
    {
      name: "¿Esta cerrada?",
      selector: row => [row.sale],
      sortable: true, 
      width:"100px",
      cell: row => <span className="text-center w-100">
        {row.isOpen === 0 ? <i className="fa fa-check" /> : ""}
      </span>
    },
    {
      name: "Estatus",
      selector: row => [row.status],
      sortable: true,
      cell: row => <span className={`text-${row.status ==="Saldo" ? "yellow" : "green" } fs-15 fw-semibold text-center w-100`}>{row.status}</span>
    },
    {
      name: "Agente",
      selector: row => [row.agent],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.agent}</span>
    },
    {
      name: "Cliente",
      selector: row => [row.customer],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{row.customer}</span>
    },
    {
      name: "Comision $",
      selector: row => [row.representativeFee],
      sortable: true,
      cell: row => <span className="text-muted fs-15 fw-semibold text-center">{currencyFormat(row.representativeFee)}</span>
    },
  ]
  const tableDatas = {
    columns,
    data:files,
  };
  return (
    <DataTableExtensions {...tableDatas} >
      <DataTable
        columns={columns}
        data={files}
        noHeader
        defaultSortField="id"
        defaultSortAsc={false}
        striped={true}
        center={true}
        persistTableHead
        pagination
        highlightOnHover />
    </DataTableExtensions>
  )
}