import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';
import { MasterSaleDetails } from './MasterSaleDetails';
import { getPaymentMethodList, getServicesList, getWholesalersList } from '../../../store/slices/general/thunks';
import { AdvancePayment } from './AdvancePayment';
import { AccountsPayable } from './AccountsPayable';
import { PaymentToWholesaler } from './PaymentToWholesaler';
import { MoneyFlow } from './MoneyFlow';
import { getMasterSaleGroup, getRoomlodgingGroupList } from '../../../store/slices/groupFiles/thunks';
import { resetMasterSaleGroup, setRoomLodgingGroupList } from '../../../store/slices/groupFiles/groupFilesSlice';
import { RejectedPayment } from './RejectedPayment';
import styles from './MasterSale.module.css';

export const MasterSale = ({ groupFile }) => {
  const dispatch = useDispatch();
  const { masterSaleGroup } = useSelector((state) => state.groupFiles);
  const advancePaymentSection = useRef(null);
  const rejectedPaymentSection = useRef(null);
  const paymentToWholesalerSection = useRef(null);
  const moneyFlowSection = useRef(null);
  const accountsPayableSection = useRef(null);

  useEffect(() => {
    if (groupFile.id) {
      dispatch(getMasterSaleGroup(groupFile.id))
    }

    return () => {
      dispatch(resetMasterSaleGroup())
    }
  }, [groupFile.id])

  useEffect(() => {
    if (groupFile.id) {
      dispatch(getRoomlodgingGroupList(groupFile.id))
    }
    return () => {
      dispatch(setRoomLodgingGroupList([]));
    }
  }, [groupFile.id])

  useEffect(() => {
    dispatch(getServicesList())
  }, [])

  useEffect(() => {
    dispatch(getWholesalersList())
  }, [])

  useEffect(() => {
    dispatch(getPaymentMethodList())
  }, [])

  const scrollDown = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 80,
      behavior: 'smooth',
    });
  };


  return (
    <div>
      <div className="d-flex" style={{ marginTop: 20, marginBottom: 20 }} >
        <button className={`btn text-white btn-info ${styles.btnSubMenu}`}
          onClick={() => scrollDown(advancePaymentSection)} >Anticipos</button>
        <button className={`btn text-white btn-info ${styles.btnSubMenu}`}
          onClick={() => scrollDown(rejectedPaymentSection)} >Pagos rechazados</button>
        <button className={`btn text-white btn-info ${styles.btnSubMenu}`}
          onClick={() => scrollDown(paymentToWholesalerSection)} >Pagos a mayoristas</button>
        <button className={`btn text-white btn-info ${styles.btnSubMenu}`}
          onClick={() => scrollDown(moneyFlowSection)} >Flujo</button>
        <button className={`btn text-white btn-info ${styles.btnSubMenu}`}
          onClick={() => scrollDown(accountsPayableSection)} >Cuentas por pagar</button>
      </div>
      <MasterSaleDetails masterSaleGroup={masterSaleGroup} groupFile={groupFile} />

      {
        masterSaleGroup.isOpen === 0 &&
        <>
          <AdvancePayment masterSaleGroup={masterSaleGroup} groupFile={groupFile} ref2={advancePaymentSection} />
          <RejectedPayment masterSaleGroup={masterSaleGroup} ref2={rejectedPaymentSection} />
          <PaymentToWholesaler masterSaleGroup={masterSaleGroup} groupFile={groupFile} ref2={paymentToWholesalerSection} />
          <MoneyFlow masterSaleGroup={masterSaleGroup} groupFile={groupFile} ref2={moneyFlowSection} />
          <AccountsPayable masterSaleGroup={masterSaleGroup} groupFile={groupFile} ref2={accountsPayableSection} />
        </>
      }
    </div>
  )
}
