import React, { useEffect } from 'react'
import { useState } from 'react';
import { AddLodging } from './AddLodging';
import { useSelector, useDispatch } from 'react-redux';
import { getLoadingGroup } from '../../../store/slices/groupFiles/thunks';
import { LodgingDetails } from './LodgingDetails';
import { getLodgingTypeList } from '../../../store/slices/general/thunks';
import { setLodgingGroupList } from '../../../store/slices/groupFiles/groupFilesSlice';

export const Lodging = ({ groupFile }) => {
  const dispatch = useDispatch();
  const { lodgingGroupList } = useSelector((state) => state.groupFiles);
  const [addLodging, setAddLodging] = useState(false);
  useEffect(() => {
    if (groupFile) {
      dispatch(getLoadingGroup(groupFile.id))
    }

    return () => {
      dispatch(setLodgingGroupList([]));
    }
  }, [groupFile.id])
  
  useEffect(() => {
      dispatch(getLodgingTypeList())
  }, [])

  return (
    <div>
      <button className="btn btn-light btn-icon" style={{ marginTop: 20 }}
        onClick={() => setAddLodging(true)} >Agregar Hospedaje</button>
      <AddLodging groupFile={groupFile} isOpen={addLodging} setIsOpen={setAddLodging} />

      {
        lodgingGroupList.map(lodging => (
          <LodgingDetails key={lodging.id} lodging={lodging} groupFile={groupFile} />
        ))
      }
    </div>
  )
}
