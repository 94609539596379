import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../../../ui/controls/MySelect";
import { MyTextInput } from "../../../ui/controls/MyTextInput";
import { MyDatePicker } from "../../../ui/controls/MyDatePicker";
import { MyTextArea2 } from "../../../ui/controls/MyTextArea2";
import { onlyIntegersDecimals } from "../../../../helpers/numericFields";
import { useSelector, useDispatch } from 'react-redux';
import { getPaymentWayList } from "../../../../store/slices/general/thunks";
import { MyCheckBox2 } from "../../../ui/controls/MyCheckBox2";
import { addPaymentMasterSale } from "../../../../store/slices/individualFiles/thunks";
import { MyFileInput } from "../../../ui/controls/MyFileInput";
import { Image_Type } from "../../../../const/Image"
import Swal from "sweetalert2";

export const AddAdvancePayment = ({ masterSale, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { paymentMethodList, paymentWayList } = useSelector((state) => state.general);

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Pago De Cliente
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            date: new Date(),
            amount: "",
            paymentMethod: undefined,
            paymentWay: undefined,
            reference: "",
            invoice: false,
            notes: "",
            image: undefined,
          }}
          onSubmit={(({ date, amount, paymentMethod, paymentWay, reference, invoice, notes, image2
          }, { resetForm, setErrors }) => {

            if (!date) {
              return setErrors({ "date": "Requerido" })
            }
            if (!paymentMethod) {
              return setErrors({ "paymentMethod": "Requerido" })
            }
            if (!paymentWay) {
              return setErrors({ "paymentWay": "Requerido" })
            }
            if(paymentMethod.label !== "EFECTIVO" && !image2){              
              return setErrors({ "image": "Requerido" })              
            }

            let date2;
            if (date.year) {
              date2 = date.year + "-" + (date.monthIndex + 1) + "-" + date.day
            } else {
              date2 = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }
            dispatch(addPaymentMasterSale(masterSale.id, date2, amount, paymentWay.value, 
              reference, image2, (invoice || invoice === 1 ? 1: 0), notes, masterSale.idIndividualFile, 
              success));
          })}
          validationSchema={
            Yup.object({
              amount: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fecha de pago:*' name="date" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa"
                          onChange={e => {
                            setFieldValue("date", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Monto:*' name='amount' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("amount", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Metodo:*' name="paymentMethod" options={paymentMethodList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("paymentMethod", e);
                            setFieldValue("paymentWay", null);
                            dispatch(getPaymentWayList(e.value))
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Forma de pago:*' name="paymentWay" options={paymentWayList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("paymentWay", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Referencia:' name='reference' mode='horizontal'
                          onChange={(e) => {
                            setFieldValue("reference", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <MyFileInput label='Comprobante' name='image' mode='horizontal'
                        onChange={(e) => {
                          if (e.target.files[0]) {
                            if (Image_Type.indexOf(e.target.files[0].type) === -1) {
                              setFieldValue("image2", undefined)
                              return Swal.fire("Pagos", "Formato no valido", "error");
                            }
                            if (e.target.files[0].size > 2000000) {
                              setFieldValue("image2", undefined)
                              return Swal.fire("Pagos", "Solo se permiten imagenes de 2MB max", "error");
                            }
                            setFieldValue("image2", e.currentTarget.files[0])
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12" >
                      <div className=" row mb-4">
                        <MyCheckBox2 label="Facturar:" name="invoice" mode='horizontal' style={{fontSize:20, marginTop:8}} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={4} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
