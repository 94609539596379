import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { useSelector, useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import { addElementToCourtshipLine } from "../../../store/slices/group/thunks";

export const AddItem = ({ idGroupFile, code, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Elemento A La Linea De Cotejo
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            name: "",
            person1: "",
            telephone1: "",
            person2: "",
            telephone2: "",
          }}
          onSubmit={(({ name, person1, telephone1, person2, telephone2
          }, { resetForm, setErrors }) => {            

            const success = () => {
              resetForm();
              setIsOpen(false)
            }
            
            dispatch(addElementToCourtshipLine(idGroupFile, name, person1, telephone1, person2, telephone2, 
              code, success));
          })}
          validationSchema={
            Yup.object({
              name: Yup.string()
                .required("Requerido"),
              person1: Yup.string()
                .required("Requerido"),
              telephone1: Yup.string()
                .required("Requerido"),
              person2: Yup.string()
                .required("Requerido"),
              telephone2: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Nombre:*' name='name' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">

                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Persona 1:*' name='person1' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Telefono 1:*' name='telephone1' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Persona 2:*' name='person2' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Telefono 2:*' name='telephone2' mode='horizontal' />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
