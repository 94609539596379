import React, { useState } from 'react'
import {  WaitingListDetails } from './WaitingListDetails'
import { useEffect } from 'react'
import { getWaitingList } from '../../../store/slices/groupFiles/thunks'
import { useDispatch } from 'react-redux'
import { AddRoom } from './AddRoom'

export const WaitingList = ({ groupFile }) => {
  const dispatch = useDispatch();
  const [addRoom2, setAddRoom2] = useState(false);

  useEffect(() => {
    dispatch(getWaitingList(groupFile.id));
  }, [])

  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ fontSize: 20 }}>Lista de espera</div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button className="btn btn-light btn-icon"
          onClick={() => setAddRoom2(true)} >Agregar Habitacion</button>
      </div>

      <AddRoom groupFile={groupFile} isOpen={addRoom2} setIsOpen={setAddRoom2} />
      <WaitingListDetails groupFile={groupFile} />
    </div>
  )
}
