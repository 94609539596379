

export const onlyIntegers= (e) => {
  const reg = /^[0-9\b]+$/
  let preval=e.target.value
  if (e.target.value === '' || reg.test(e.target.value)) {
    return true
  } else {
    e.target.value = preval.substring(0,(preval.length-1))
  } 
}

export const onlyIntegersDecimals= (e) => {
  const reg = /^\d*\.?\d*$/
  let preval=e.target.value
  if (e.target.value === '' || reg.test(e.target.value)) {
    return true
  } else {
    e.target.value = preval.substring(0,(preval.length-1))
  } 
}


export const currencyFormat = (num) => {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const currentDate = (days=0) => {
  let today = new Date();
  let newDate = new Date();
  newDate.setDate(today.getDate()+days);
  var dd = String(newDate.getDate()).padStart(2, '0');
  var mm = String(newDate.getMonth() + 1).padStart(2, '0');
  var yyyy = newDate.getFullYear();
  
  return `${yyyy}-${mm}-${dd}`
}