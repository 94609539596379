import { useState } from 'react';
import { Row } from 'react-bootstrap'
import { currencyFormat } from '../../helpers/numericFields'
import styles from './CruiseDetails.module.css'
import { AddCabin } from './AddCabin';
import { CabinDetails } from './CabinDetails';
import { EditCruise } from './EditCruise';

export const CruiseDetails = ({ cruise, index }) => {
  const [editCruise, setEditCruise] = useState(false);
  const [addCabin, setAddCabin] = useState(false);

  return (
    <>
      <Row className={styles.container}>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Naviera:</div>
        <div className={`col-lg-4 col-md-4 ${styles.edit}`} onClick={() => setEditCruise(true)}>{cruise.company}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Mayorista:</div>
        <div className="col-lg-4 col-md-4">{cruise.wholesaler}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Barco:</div>
        <div className="col-lg-4 col-md-4">{cruise.ship}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Puerto:</div>
        <div className="col-lg-4 col-md-4">{cruise.port}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Salida:</div>
        <div className="col-lg-4 col-md-4">{cruise.departureDate}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Regreso:</div>
        <div className="col-lg-4 col-md-4">{cruise.returnDate}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Ruta:</div>
        <div className="col-lg-10 col-md-10">{cruise.route}</div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle}`}>Notas:</div>
        <div className="col-lg-10 col-md-10">{cruise.notes}</div>
        <div style={{ height: 10 }}></div>
        {
          cruise.cabins.map(cabin => (
            <CabinDetails key={cabin.id} cabin={cabin} idQuote={cruise.idQuote} />
          ))
        }
        <div className={`col-lg-7 col-md-7`}></div>
        <div className={`col-lg-2 col-md-2 ${styles.subTitle} ${styles.subTotal}`}>Sub Total:</div>
        <div className={`col-lg-3 col-md-3 ${styles.subTotal}`}>{cruise.currency} {currencyFormat(cruise.cabins.reduce((a, b) => a + b.rate + b.tax + b.tip, 0))}</div>
        <div style={{ height: 10 }}></div>
        <button className="btn btn-light" onClick={() => setAddCabin(true)}>+Cabina</button>
        <AddCabin cruise={cruise} isOpen={addCabin} setIsOpen={setAddCabin} />
        <div className={`col-12 ${styles.line}`}></div>

      </Row>
      <EditCruise cruise={cruise} isOpen={editCruise} setIsOpen={setEditCruise} />
    </>
  )
}
