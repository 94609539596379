import React from 'react'
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPaymentMethodList } from '../../store/slices/general/thunks';
import { Header } from './Header';
import { Details as DetailsGuest } from '../Guest/Details';
import { Details } from './Details';

export const GroupScreen = () => {
  const dispatch = useDispatch();
  const { info } = useSelector((state) => state.group);  

  useEffect(() => {
    dispatch(getPaymentMethodList())
  }, [])

  return (
    <div>
      <Header info={info} />
      <div >
        Nota: Si tienes alguna duda respecto de tus pagos, por favor comunicate al 614 192 2828 o 614 362 3709
      </div>

      <Details info={info} />

      {
        info.individualFiles.map(item => (
          <DetailsGuest key={item.id} info={item} />
        ))
      }

    </div>
  )
}
