import { createSlice } from '@reduxjs/toolkit';

const initialMasterSale = {
  groupRooms: [],
  services: [],
  air: [],
  lodging: [],
  cruise: [],
  service: [],
  accountsPayable: [],
  advancePayments: [],
  paymentsToWholesalerServices: [],
  paymentsToWholesalerAir: [],
  paymentsToWholesalerLodging: [],
  paymentsToWholesalerCruise: [],
  paymentsToWholesalerService: [],
  groupSales: [],
  isOpen: 1,
}

const initialCustomerPayments = {
  files: [],
  payments: []
}

export const individualFilesSlice = createSlice({
  name: 'individualFiles',
  initialState: {
    individualFile: {},
    passengersList: [],
    masterSale: initialMasterSale,
    optionSelected: "Quotes",
    customerPayments: initialCustomerPayments,
    roomingList: [],
    pendingPayments: [],
    pendingAirportHotel: [],
    pendingHotelAirport: [],
  },
  reducers: {
    setIndividualFile: (state, action) => {
      state.individualFile = action.payload;
    },
    setPassengersList: (state, action) => {
      state.passengersList = action.payload;
    },
    setMasterSale: (state, action) => {
      state.masterSale = action.payload;
    },
    resetMasterSale: (state, action) => {
      state.masterSale = initialMasterSale;
    },
    setOptionSelected: (state, action) => {
      state.optionSelected = action.payload;
    },
    setCustomerPayments: (state, action) => {
      state.customerPayments = action.payload;
    },
    resetCustomerPayments: (state, action) => {
      state.customerPayments = initialCustomerPayments;
    },
    setRoomingList: (state, action) => {
      state.roomingList = action.payload;
    },
    setPendingPayments: (state, action) => {
      state.pendingPayments = action.payload;
    },
    setPendingAirportHotel: (state, action) => {
      state.pendingAirportHotel = action.payload;
    },
    setPendingHotelAirport: (state, action) => {
      state.pendingHotelAirport = action.payload;
    },
  }
});


// Action creators are generated for each case reducer function
export const { setIndividualFile, setPassengersList, setMasterSale, resetMasterSale, setOptionSelected,
  setCustomerPayments, resetCustomerPayments,
  setRoomingList, setPendingPayments, setPendingAirportHotel, setPendingHotelAirport } = individualFilesSlice.actions;