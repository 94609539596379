import styles from './MyTextLabel.module.css'


export const MyTextLabel84 = ({ label, value, mode="vertical", ...props }) => {
  return <>
    {
      mode === "horizontal" &&
      <div className="mb-3 row">
        <label className='col-form-label col-8' htmlFor={props.id || props.name} >{label}</label>
        <div className="col-4">
          <label className={`${styles.value} col-form-label col-12`} >{value}</label>              
        </div>
      </div>
    }
    {
      mode === "vertical" &&
      <div className="mb-3">
        <label className='form-label' htmlFor={props.id || props.name} >{label}</label>
        <label className={`${styles.value} form-label`} >{value}</label>
      </div>
    }
  </>
}
