import { useRef, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../ui/controls/MyTextInput";
import { MySelect } from "../ui/controls/MySelect";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { WithContext as ReactTags } from 'react-tag-input';
import '../../assets/css/reactTags.css'
import '../../assets/css/react-select-search.css'
import { MyDatePicker } from "../ui/controls/MyDatePicker";
import { MyTextArea2 } from "../ui/controls/MyTextArea2";
import { onlyIntegers } from "../../helpers/numericFields";
import { addFile } from "../../store/slices/files/thunks";

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const AddFile = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { customersList, categoriesList } = useSelector((state) => state.general);
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState({});

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  const handleAdditionButton = tag => {
    if (tag.id) {
      const item = tags.find(x => x.id === tag.id);
      if (!item) {
        setTag({})
        handleAddition(tag)
      }
    }
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = index => {

  };

  const searchInput = useRef(null);

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Crear Expediente
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            customer: undefined,
            category: undefined,
            tags: '',
            requestDate: '',
            startEndDate: '',
            paydayLimit: '',
            adults: "",
            juniors: "",
            minors: "",
            infants: "",
            notes: '',
          }}
          onSubmit={(({ customer, category, requestDate, startEndDate, paydayLimit, adults, juniors, minors, infants, notes },
            { resetForm, setErrors }) => {
            if (!customer) {
              return setErrors({ "customer": "Requerido" })
            }
            if (!category) {
              return setErrors({ "category": "Requerido" })
            }
            if (tags.length === 0) {
              return setErrors({ "tags": "Requerido" })
            }
            if (!requestDate) {
              return setErrors({ "requestDate": "Requerido" })
            }
            if (!startEndDate || startEndDate.length !== 2) {
              return setErrors({ "startEndDate": "Requerido" })
            }
            const success = () => {
              resetForm();
              setTags([]);
              setIsOpen(false)
            }

            dispatch(addFile(customer.value, category.value, tags.map(item => item.text).join(", "),
              requestDate.year + "-" + (requestDate.monthIndex + 1) + "-" + requestDate.day,
              startEndDate[0].year + "-" + (startEndDate[0].monthIndex + 1) + "-" + startEndDate[0].day,
              startEndDate[1].year + "-" + (startEndDate[1].monthIndex + 1) + "-" + startEndDate[1].day,
              paydayLimit ? paydayLimit.year + "-" + (paydayLimit.monthIndex + 1) + "-" + paydayLimit.day : undefined,
              adults, juniors, minors, infants, notes, success));

          })}
          validationSchema={
            Yup.object({
              // idContactWay: Yup.string()
              //   .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-12 col-md-12">
                      {/* for bug on select item in select search */}
                      <input ref={searchInput} style={{ display: "block", width: 0 }}></input>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Cliente:*' name="customer" options={customersList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("customer", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Categoria:*' name="category" options={categoriesList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("category", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <div className="mb-3 row">
                          <label className='col-form-label col-4' htmlFor="destinations" >Destinos:*</label>
                          <div className="col-7">
                            <ReactTags
                              tags={tags}
                              placeholder=""
                              delimiters={delimiters}
                              handleDelete={handleDelete}
                              handleAddition={handleAddition}
                              handleDrag={handleDrag}
                              handleTagClick={handleTagClick}
                              handleInputChange={(e) => setTag({ id: e, text: e })}
                              inputFieldPosition="top"
                              autocomplete
                            />
                            <ErrorMessage name="tags" component="span" style={{ color: "red" }} />
                          </div>
                          <div className="col-1">
                            <Button onClick={() => handleAdditionButton(tag)}>+</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fecha Solicitud:*' name="requestDate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa"
                          onChange={e => {
                            setFieldValue("requestDate", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fechas Viaje:*' name="startEndDate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                          range
                          onChange={e => {
                            setFieldValue("startEndDate", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fecha limite de pago:' name="paydayLimit" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa"
                          onChange={e => {
                            setFieldValue("paydayLimit", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Adultos:' name='adults' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("adults", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Juniors:' name='juniors' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("juniors", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Menores:' name='minors' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("minors", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Infantes:' name='infants' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("infants", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={4} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  );
}
