import React from "react";
import {
  Card,
  Row,
  Button,
} from "react-bootstrap";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { useSelector } from 'react-redux';
import { getFilesList } from "../../store/slices/files/thunks";
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../ui/controls/MyTextInput";
import { onlyIntegers } from "../../helpers/numericFields";
import { MySelect } from "../ui/controls/MySelect";
import { fileByRep } from "../../store/slices/representative/thunks";

export const  FormSearchRepresentative = () => {
  const dispatch = useDispatch();
  const { representativesList } = useSelector((state) => state.general);

  return (
    <div>

      <Row>
        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
          <Card>
            <Card.Header>
              <h4 className="card-title">Buscar Agente</h4>
            </Card.Header>
            <Card.Body>
              <Formik
                initialValues={{
                  representative: undefined,
                }}
                onSubmit={(({ representative },
                  { resetForm, setErrors }) => {

                    if (!representative) {
                      return setErrors({ "representative": "Requerido" })
                    }
                    dispatch(fileByRep(representative.value));

                })}
                validationSchema={
                  Yup.object({
                  })
                }
              >

                {
                  ({ setFieldValue }) => (
                    <Form>
                      <Row>
                        <div className="col-lg-6 col-md-12">
                          <div className=" row mb-4">
                            <MySelect label='Agente:*' name="representative" options={[{value:"",label:""},...representativesList]} mode='horizontal'
                              singleSelect="true"
                              onChange={e => {
                                setFieldValue("representative", e);
                              }} />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-12">
                          <div className=" row mb-4">
                            <Button variant="primary" type="submit">
                              Buscar
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </Form>
                  )
                }
              </Formik>
            </Card.Body>
          </Card>
        </div>
      </Row>
    </div>
  );
}
