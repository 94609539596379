import { useDispatch, useSelector } from "react-redux";
import styles from './TransportScreen.module.css'
import { getApprovedRequestAirportHotel, getApprovedRequestHotelAirport, getTransportChurch } from "../../../store/slices/groupFiles/thunks";



export const TransportScreen = ({ groupFile }) => {
  const dispatch = useDispatch();

  const downloadAirportHotel = ()=> {
    dispatch(getApprovedRequestAirportHotel(groupFile.id));
  }

  const downloadHotelAirport = ()=> {
    dispatch(getApprovedRequestHotelAirport(groupFile.id));    
  }

  const downloadHotelChurch = ()=> {
    dispatch(getTransportChurch(groupFile.id));    
  }

  return (
    <div className={`${styles.mainDiv}`} >
      <div className="row mb-6">
        <div className="col-4">
          <button className="btn btn-primary btn-info "
            onClick={() => downloadAirportHotel()}>Aeropuerto - Hotel</button>
        </div>
        <div className="col-4">
          <button className="btn btn-primary btn-info "
            onClick={() => downloadHotelAirport()}>Hotel - Aeropuerto</button>
        </div>
        <div className="col-4">
          <button className="btn btn-primary btn-info "
            onClick={() => downloadHotelChurch()}>Hotel - Iglesia</button>
        </div>
      </div>
    </div>
  )
}
