import { useState, useEffect } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../../ui/controls/MySelect";
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { onlyIntegers, } from "../../../helpers/numericFields";
import { useSelector, useDispatch } from 'react-redux';
import { deleteRoomMasterSaleGroup, editRoomMasterSaleGroup } from "../../../store/slices/groupFiles/thunks";

export const EditRoom = ({room, masterSaleGroup, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { roomLodgingGroupList } = useSelector((state) => state.groupFiles);
  const [rooms, setRooms] = useState([])

  useEffect(() => {
    const items = [];
    for (const lodging of roomLodgingGroupList) {
      for (const room of lodging.rooms) {
        items.push({"value":room.id, "label": lodging.name + " - " + room.room})
      }
    }
    setRooms(items)
  }, [roomLodgingGroupList])

  const delete2 = () => {
    const success = () => {
      setIsOpen(false)
    }
    dispatch(deleteRoomMasterSaleGroup(room.id, masterSaleGroup.idGroupFile, success));
  }

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Partida De Hospedaje
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            room: {"value":room.idRoomLodgingGroup, "label": room.hotel + " - " + room.room},
            quantity: room.quantity,
          }}
          onSubmit={(({ room:room2, quantity}, { resetForm, setErrors }) => {

            if (!room2) {
              return setErrors({ "room": "Requerido" })
            }
            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(editRoomMasterSaleGroup(room.id, room2.value, quantity, masterSaleGroup.idGroupFile,
               success));
          })}
          validationSchema={
            Yup.object({
              quantity: Yup.string()
              .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-8 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Hospedaje:*' name="room" options={rooms} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("room", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Cantidad:' name='quantity' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("quantity", e.target.value);
                          }} />
                      </div>
                    </div>                    
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <div style={{ width: "100%" }}>
                    <Button variant="danger" type="button" style={{ float: "left" }} onClick={delete2}>
                      Eliminar
                    </Button>
                    <Button variant="primary" type="submit" style={{ float: "right" }}>
                      Guardar Informacion
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
