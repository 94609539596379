import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../../ui/controls/MyTextInput";
import {  useDispatch } from 'react-redux';
import { addHotel  } from "../../../store/slices/catalogue/thunks";
import { MyTextArea2 } from "../../ui/controls/MyTextArea2";

export const AddHotel = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Hotel
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            name: '',
            code: '',
            address: '',
            city: '',
            state: '',
            country: '',
            telephone: '',
            email: '',
            notes: '',
          }}
          onSubmit={(({ name, code, address, city, state, country, telephone,
            email, notes }, { resetForm, setErrors }) => {

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(addHotel(name, code, address, city, state, country, telephone,
              email, notes, success));

          })}
          validationSchema={
            Yup.object({
              name: Yup.string()
                .required("Requerido"),
              code: Yup.string()
                .required("Requerido"),
              email: Yup.string()
                .email("Formato de correo invalido")
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Nombre:*' name='name' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Codigo:*' name='code' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Direccion:' name='address' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Ciudad:' name='city' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Estado:' name='state' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Pais:' name='country' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Telefono:' name='telephone' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Correo Electronico:' name='email' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={4} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  );
}
