import { useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { currencyFormat } from "../../../helpers/numericFields";
import { EditPaymentWholesaler } from "./EditPaymentWholesaler";
import styles from './PaymentToWholesalerDetails.module.css'

export const PaymentToWholesalerDetails = ({ masterSale }) => {
  const { role } = useSelector((state) => state.auth);
  const [editPaymentWholesaler, setEditPaymentWholesaler] = useState(false);
  const [paymentSelected, setPaymentSelected] = useState({});

  const edit2 = (payment) => {
    setEditPaymentWholesaler(true);
    setPaymentSelected(payment)
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Pago #</th>
              <th>Fecha</th>
              <th>Mayorista</th>
              <th>Concepto</th>
              <th>Descripcion</th>
              <th>Metodo</th>
              <th>Forma De Pago</th>              
              <th>Monto</th>
              <th>Notas</th>
              {
                role === "DIRECTOR"
                && <th>Editar</th>
              }
            </tr>
          </thead>
          <tbody>
            {
              masterSale.paymentsToWholesalerServices.map((payment, index) => (
                <tr key={payment.id + payment.concept}>
                  <td>{index + 1}</td>
                  <td>{payment.date}</td>
                  <td>{payment.wholesaler}</td>
                  <td>{payment.concept}</td>
                  <td>{payment.service}</td>
                  <td>{payment.paymentMethod}</td>
                  <td>{payment.paymentWay}</td>                  
                  <td>{currencyFormat(payment.amount)}</td>
                  <td>{payment.notes}</td>
                  {
                    role === "DIRECTOR"
                    && <td style={{ textAlign: "center" }}>
                      <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(payment)}></i>
                    </td>
                  }                   
                </tr>))
            }
            {
              masterSale.paymentsToWholesalerAir.map((payment, index) => (
                <tr key={payment.id + payment.concept}>
                  <td>{index + 1}</td>
                  <td>{payment.date}</td>
                  <td>{payment.wholesaler}</td>
                  <td>{payment.concept}</td>
                  <td>{payment.description}</td>
                  <td>{payment.paymentMethod}</td>
                  <td>{payment.paymentWay}</td>                  
                  <td>{currencyFormat(payment.amount)}</td>
                  <td>{payment.notes}</td>
                  {
                    role === "DIRECTOR"
                    && <td style={{ textAlign: "center" }}>
                    <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(payment)}></i>
                  </td>
                  }                  
                </tr>))
            }
            {
              masterSale.paymentsToWholesalerLodging.map((payment, index) => (
                <tr key={payment.id + payment.concept}>
                  <td>{index + 1}</td>
                  <td>{payment.date}</td>
                  <td>{payment.wholesaler}</td>
                  <td>{payment.concept}</td>
                  <td>{payment.description}</td>
                  <td>{payment.paymentMethod}</td>
                  <td>{payment.paymentWay}</td>                  
                  <td>{currencyFormat(payment.amount)}</td>
                  <td>{payment.notes}</td>
                  {
                    role === "DIRECTOR"
                    && <td style={{ textAlign: "center" }}>
                      <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(payment)}></i>
                    </td>
                  }                   
                </tr>))
            }
            {
              masterSale.paymentsToWholesalerCruise.map((payment, index) => (
                <tr key={payment.id + payment.concept}>
                  <td>{index + 1}</td>
                  <td>{payment.date}</td>
                  <td>{payment.wholesaler}</td>
                  <td>{payment.concept}</td>
                  <td>{payment.description}</td>
                  <td>{payment.paymentMethod}</td>
                  <td>{payment.paymentWay}</td>                  
                  <td>{currencyFormat(payment.amount)}</td>
                  <td>{payment.notes}</td>
                  {
                    role === "DIRECTOR"
                    && <td style={{ textAlign: "center" }}>
                      <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(payment)}></i>
                    </td>
                  }                  
                </tr>))
            }
            {
              masterSale.paymentsToWholesalerService.map((payment, index) => (
                <tr key={payment.id + payment.concept}>
                  <td>{index + 1}</td>
                  <td>{payment.date}</td>
                  <td>{payment.wholesaler}</td>
                  <td>{payment.concept}</td>
                  <td>{payment.description}</td>
                  <td>{payment.paymentMethod}</td>
                  <td>{payment.paymentWay}</td>                  
                  <td>{currencyFormat(payment.amount)}</td>
                  <td>{payment.notes}</td>
                  {
                    role === "DIRECTOR"
                    && <td style={{ textAlign: "center" }}>
                      <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(payment)}></i>
                    </td>
                  }                  
                </tr>))
            }
          </tbody>
        </Table>
      </div>
      <EditPaymentWholesaler payment={paymentSelected} masterSale={masterSale} 
        isOpen={editPaymentWholesaler} setIsOpen={setEditPaymentWholesaler} />
      <div className={styles.total}>Total: {
        currencyFormat(
          masterSale.paymentsToWholesalerServices.reduce((a, b) => a += b.amount, 0) +
          masterSale.paymentsToWholesalerAir.reduce((a, b) => a += b.amount, 0) +
          masterSale.paymentsToWholesalerLodging.reduce((a, b) => a += b.amount, 0) +
          masterSale.paymentsToWholesalerCruise.reduce((a, b) => a += b.amount, 0) +
          masterSale.paymentsToWholesalerService.reduce((a, b) => a += b.amount, 0)
          )}
      </div>
    </div>
  )
}
