import React, { useEffect } from 'react'
import { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getCustomersList } from '../../../store/slices/general/thunks';
import { setIndividualFilesList, setInfoIndividualSale, setLodgingGroupList, setRoomLodgingGroupList } from '../../../store/slices/groupFiles/groupFilesSlice';
import { getLoadingGroup, getRoomlodgingGroupList } from '../../../store/slices/groupFiles/thunks';
import { getFilesByIdGroup } from '../../../store/slices/individualFiles/thunks';
import { AddCustomer } from './AddCustomer';
import { IndividualSaleDetails } from './IndividualSaleDetails';
import { SearchTable } from './SearchTable';

export const IndividualSale = ({ groupFile }) => {
  const dispatch = useDispatch();
  const { individualSale } = useSelector((state) => state.groupFiles);
  const [addCustomer, setAddCustomer] = useState(false);

  useEffect(() => {
      dispatch(getCustomersList())
  }, [])

  useEffect(() => {
    if (groupFile.id) {
      dispatch(getRoomlodgingGroupList(groupFile.id))
    }

    return () => {
      dispatch(setRoomLodgingGroupList([]));
    }
  }, [groupFile.id])

  useEffect(() => {
    if (groupFile.id) {
      dispatch(getFilesByIdGroup(groupFile.id));
    }

    return () => {
      dispatch(setIndividualFilesList([]));
    }
  }, [groupFile.id])

  useEffect(() => {
    dispatch(setInfoIndividualSale({customer:{}, rooms:[]}));

    return () => {
      dispatch(setInfoIndividualSale({customer:{}, rooms:[]}));
    }
  }, [])


  return (
    <div>
      {
        individualSale && individualSale.customer && individualSale.customer.value
          ? <IndividualSaleDetails individualSale={individualSale} groupFile={groupFile} />
          : <button className="btn btn-light btn-icon" style={{ marginTop: 20, marginBottom: 20 }}
            onClick={() => setAddCustomer(true)} >Agregar Venta Individual</button>
      }
      <AddCustomer groupFile={groupFile} isOpen={addCustomer} setIsOpen={setAddCustomer} />
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h3 className="card-title mb-0">Expedientes</h3>
            </Card.Header>
            <Card.Body>
              <div className="dataTable">
                <div className="table-responsive">
                  <SearchTable />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
