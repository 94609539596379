import { Card, Col, Row, Table } from "react-bootstrap";

export const PeopleControl = ({ peopleSummary }) => {

  return (
    <>
      <Row>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Header>
              <h3 className="card-title mb-0">Huespedes</h3>
            </Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <Table className="table text-nowrap text-md-nowrap mg-b-0">
                  <tbody>
                    {
                      peopleSummary.adults > 0 &&
                      <tr >
                        <td>Adultos</td>
                        <td>{peopleSummary.adults}</td>
                      </tr>
                    }
                    {
                      peopleSummary.juniors > 0 &&
                      <tr >
                        <td>Juniors</td>
                        <td>{peopleSummary.juniors}</td>
                      </tr>
                    }
                    {
                      peopleSummary.minors > 0 &&
                      <tr >
                        <td>Menores</td>
                        <td>{peopleSummary.minors}</td>
                      </tr>
                    }
                    {
                      peopleSummary.infants > 0 &&
                      <tr >
                        <td>Infantes</td>
                        <td>{peopleSummary.infants}</td>
                      </tr>
                    }
                    <tr style={{ fontWeight: "bold" }}>
                      <td>Total</td>
                      <td>{peopleSummary.adults + peopleSummary.juniors + peopleSummary.minors + peopleSummary.infants}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}
