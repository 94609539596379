import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../../../ui/controls/MySelect";
import { MyTextInput } from "../../../ui/controls/MyTextInput";
import { MyDatePicker } from "../../../ui/controls/MyDatePicker";
import { MyTextArea2 } from "../../../ui/controls/MyTextArea2";
import { onlyIntegers, onlyIntegersDecimals } from "../../../../helpers/numericFields";
import { useSelector, useDispatch } from 'react-redux';
import { MyTextInput66 } from "../../../ui/controls/MyTextInput66";
import { addServiceMasterSale } from "../../../../store/slices/individualFiles/thunks";

export const AddService = ({ masterSale, individualFile, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { servicesList, wholesalersList } = useSelector((state) => state.general);

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Partida De Servicio
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            service: undefined,
            wholesaler: undefined,
            startEndDate: [individualFile.startDate, individualFile.endDate],
            quantity: "",
            ticket: "",
            reserve: "",
            description: "",
            costPerUnit: "",
            feeService: "",
            tax: "",
            others: "",
            fee: "",
          }}
          onSubmit={(({ service, wholesaler, startEndDate, quantity, ticket, reserve, description,
            costPerUnit, feeService, tax, others, fee }, { resetForm, setErrors }) => {

            let startDate;
            if (startEndDate[0].year) {
              startDate = startEndDate[0].year + "-" + (startEndDate[0].monthIndex + 1) + "-" + startEndDate[0].day
            } else {
              const temp = startEndDate[0].split("-")
              startDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }
            let endDate;
            if (startEndDate[1].year) {
              endDate = startEndDate[1].year + "-" + (startEndDate[1].monthIndex + 1) + "-" + startEndDate[1].day
            } else {
              const temp = startEndDate[1].split("-")
              endDate = temp[2] + "-" + temp[1] + "-" + temp[0]
            }

            if (!startEndDate || startEndDate.length !== 2) {
              return setErrors({ "startEndDate": "Requerido" })
            }
            if (!service) {
              return setErrors({ "service": "Requerido" })
            }
            if (!wholesaler) {
              return setErrors({ "wholesaler": "Requerido" })
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            if(!feeService) feeService=0
            if(!tax) tax=0
            if(!others) others=0
            if(!fee) fee=0

            dispatch(addServiceMasterSale(masterSale.id, service.value, wholesaler.value, startDate, endDate,
              quantity, ticket, reserve, description, costPerUnit, feeService, tax, others, fee, 
              masterSale.idIndividualFile, success));
          })}
          validationSchema={
            Yup.object({
              quantity: Yup.string()
                .required("Requerido"),
              costPerUnit: Yup.string()
                .required("Requerido"),
              description: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Servicio:*' name="service" options={servicesList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("service", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Mayorista:*' name="wholesaler" options={wholesalersList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("wholesaler", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fechas:*' name="startEndDate" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                          range
                          onChange={e => {
                            setFieldValue("startEndDate", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Cantidad:*' name='quantity' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegers(e)) {
                              return
                            }
                            setFieldValue("quantity", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Boleto:' name='ticket' mode='horizontal'
                          onChange={(e) => {
                            setFieldValue("ticket", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Reserva:' name='reserve' mode='horizontal'
                          onChange={(e) => {
                            setFieldValue("reserve", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Descripcion:*' name='description' mode='horizontal' rows={4} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Costo x Unidad:*' name='costPerUnit' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("costPerUnit", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Cuota servicio:' name='feeService' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("feeService", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Impuestos (%):' name='tax' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("tax", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Otros:' name='others' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("others", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput66 label='Comision:' name='fee' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("fee", e.target.value);
                          }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
