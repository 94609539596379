import { createSlice } from '@reduxjs/toolkit';

export const generalSlice = createSlice({
  name: 'general',
  initialState: {
    currenciesList: [{value:1, label:"MXN"}],
    airlinesList: [],
    wholesalersList: [],
    hotelsList: [],
    servicesList: [],
    paymentMethodList: [],
    paymentWayList: [],
    customersList: [],
    filterMonthList: [],
    contactWayList: [],
    categoriesList: [],
    lodgingTypeList: [],    
    usersList: [],    
    representativesList: [],    
  },
  reducers: {
    setCurrenciesList: (state, action) => {
      state.currenciesList = action.payload;
    },
    setAirlinesList: (state, action) => {
      state.airlinesList = action.payload;
    },
    setWholesalersList: (state, action) => {
      state.wholesalersList = action.payload;
    },
    setHotelsList: (state, action) => {
      state.hotelsList = action.payload;
    },
    setServicesList: (state, action) => {
      state.servicesList = action.payload;
    },
    setPaymentMethodList: (state, action) => {
      state.paymentMethodList = action.payload;
    },
    setPaymentWayList: (state, action) => {
      state.paymentWayList = action.payload;
    },
    setCustomersList: (state, action) => {
      state.customersList = action.payload;
    },
    setFilterMonthList: (state, action) => {
      state.filterMonthList = action.payload;
    },
    setContactWayList: (state, action) => {
      state.contactWayList = action.payload;
    },
    setCategoriesList: (state, action) => {
      state.categoriesList = action.payload;
    },
    setLodgingTypeList: (state, action) => {
      state.lodgingTypeList = action.payload;
    },    
    setUsersList: (state, action) => {
      state.usersList = action.payload;
    },  
    setRepresentativesList: (state, action) => {
      state.representativesList = action.payload;
    },  
  }
});


// Action creators are generated for each case reducer function
export const { setCurrenciesList, setAirlinesList, setWholesalersList, setHotelsList, setServicesList,
  setPaymentMethodList, setPaymentWayList, setCustomersList, setFilterMonthList, setContactWayList, 
  setCategoriesList, setLodgingTypeList, setUsersList, setRepresentativesList
} = generalSlice.actions;