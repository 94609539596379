import { createSlice } from '@reduxjs/toolkit';

export const guestSlice = createSlice({
  name: 'guest',
  initialState: {    
    info: {
      masterSale: {      
        groupRooms: [],
        services: [],
        air: [],
        lodging: [],
        cruise: [],
        service: [],
        advancePayments: [],
        rejectedPayments: [],
      },
    },
    transportRequests:[],
    hotelImages:[]
  },
  reducers: {   
    setInfo: (state, action) => {
      state.info = action.payload;
    }, 
    setTransportRequests: (state, action) => {
      state.transportRequests = action.payload;
    }, 
    setHotelImages: (state, action) => {
      state.hotelImages = action.payload;
    },   
  }
});


// Action creators are generated for each case reducer function
export const { setInfo, setTransportRequests, setHotelImages
  } = guestSlice.actions;