import React, { useState } from 'react'
import { AgencyPaymentSchemeDetails } from './AgencyPaymentSchemeDetails'
import { useEffect } from 'react'
import { getAgencyPaymentScheme } from '../../../store/slices/groupFiles/thunks'
import { useDispatch } from 'react-redux'
import { AddAgencyPaymentScheme } from './AddPaymentScheme'

export const AgencyPaymentScheme = ({ masterSaleGroup, groupFile }) => {
  const dispatch = useDispatch();
  const [addPaymentScheme, setAddPaymentScheme] = useState(false);

  useEffect(() => {
    dispatch(getAgencyPaymentScheme(groupFile.id));
  }, [])

  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ fontSize: 20 }}>Plan de pagos a mayoristas</div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button className="btn btn-light btn-icon" style={{ marginTop: 10 }}
          onClick={() => setAddPaymentScheme(true)} >Agregar Pago</button>
      </div>

      <AddAgencyPaymentScheme groupFile={groupFile} isOpen={addPaymentScheme} setIsOpen={setAddPaymentScheme} />
      <AgencyPaymentSchemeDetails groupFile={groupFile} />
    </div>
  )
}
