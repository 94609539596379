import { useState } from "react";
import { Table } from "react-bootstrap";
import { currencyFormat } from "../../../helpers/numericFields";
import styles from './MasterSaleDetails.module.css';
import { AddService } from './AddService';
import { AddRoom } from './AddRoom';
import { useDispatch, useSelector } from 'react-redux';
import { downloadPayments, openCloseMasterSale } from '../../../store/slices/groupFiles/thunks';
import { EditRoom } from "./EditRoom";
import { EditService } from "./EditService";


export const MasterSaleDetails = ({ masterSaleGroup, groupFile }) => {
  const { role } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [addRoom, setAddRoom] = useState(false);
  const [addService, setAddService] = useState(false);
  const [editRoom, setEditRoom] = useState(false);
  const [editService, setEditService] = useState(false);
  const [roomSelected, setRoomSelected] = useState({});
  const [serviceSelected, setServiceSelected] = useState({});

  const editRoom2 = (room) => {
    setEditRoom(true);
    setRoomSelected(room)
  }

  const editService2 = (service) => {
    setEditService(true);
    setServiceSelected(service)
  }

  const openCloseMasterSale2 = (status) => {
    dispatch(openCloseMasterSale(masterSaleGroup.id, status, groupFile.id))
  }

  const downloadPayments2 = () => {
    dispatch(downloadPayments(groupFile.id))
  }

  return (
    <>
      <div style={{ fontSize: 20 }}>Venta Maestra</div>
      {
        (masterSaleGroup.isOpen === 1 && masterSaleGroup.id) &&
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
          <button className="btn btn-light btn-icon"
            onClick={() => setAddRoom(true)} >Agregar Partida Hospedaje</button>
          <button className="btn btn-light btn-icon"
            onClick={() => setAddService(true)} >Agregar Partida Servicio</button>
          <AddRoom masterSaleGroup={masterSaleGroup} groupFile={groupFile} isOpen={addRoom} setIsOpen={setAddRoom} />
          <AddService masterSaleGroup={masterSaleGroup} groupFile={groupFile} isOpen={addService} setIsOpen={setAddService} />
        </div>
      }

      <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
        <div className="table-responsive">
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>Concepto</th>
                <th>Cantidad</th>
                <th>Descripcion</th>
                <th>Fechas</th>
                <th>Mayorista</th>
                <th>Localizador</th>
                <th>Reserva</th>
                <th>Base</th>
                <th>Cuota Servicio</th>
                <th>Impuestos</th>
                <th>Otros</th>
                <th>Comision</th>
                <th>Tarifa Publica</th>
                <th>Tarifa Neta</th>
                {
                  masterSaleGroup.isOpen === 1 && <th>Editar</th>
                }
              </tr>
            </thead>
            <tbody>
              {
                masterSaleGroup.rooms.map(item => (
                  <tr key={item.id}>
                    <td>Hospedaje</td>
                    <td>{item.quantity}</td>
                    <td>{item.hotel} - {item.room}</td>
                    <td>{item.checkInDate}<br />{item.checkOutDate}</td>
                    <td>{item.wholesaler}</td>
                    <td>{item.locator}</td>
                    <td>{item.reserve}</td>
                    <td>{currencyFormat(item.costPerNight)}</td>
                    <td>{currencyFormat(item.feeService)}</td>
                    <td>{item.tax + " %"}</td>
                    <td>{currencyFormat(item.others)}</td>
                    <td>{currencyFormat(item.fee)}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td>{currencyFormat(item.netPriceTotal)}</td>
                    {
                      masterSaleGroup.isOpen === 1
                      && <td style={{ textAlign: "center" }}>
                        <i className={`fe fe-edit ${styles.edit}`} onClick={() => editRoom2(item)}></i>
                      </td>
                    }
                  </tr>))
              }
              {
                masterSaleGroup.services.map(item => (
                  <tr key={item.id}>
                    <td>{item.service}</td>
                    <td>{item.quantity}</td>
                    <td>{item.description}</td>
                    <td>{item.startDate}<br />{item.endDate}</td>
                    <td>{item.wholesaler}</td>
                    <td>{item.ticket}</td>
                    <td>{item.reserve}</td>
                    <td>{currencyFormat(item.costPerUnit)}</td>
                    <td>{currencyFormat(item.feeService)}</td>
                    <td>{item.tax + " %"}</td>
                    <td>{currencyFormat(item.others)}</td>
                    <td>{currencyFormat(item.fee)}</td>
                    <td>{currencyFormat(item.publicPriceTotal)}</td>
                    <td>{currencyFormat(item.netPriceTotal)}</td>
                    {
                      masterSaleGroup.isOpen === 1
                      && <td style={{ textAlign: "center" }}>
                        <i className={`fe fe-edit ${styles.edit}`} onClick={() => editService2(item)}></i>
                      </td>
                    }
                  </tr>))
              }
            </tbody>
          </Table>
        </div>
        <EditRoom room={roomSelected} masterSaleGroup={masterSaleGroup}
          isOpen={editRoom} setIsOpen={setEditRoom} />
        <EditService service={serviceSelected} masterSaleGroup={masterSaleGroup}
          isOpen={editService} setIsOpen={setEditService} />
        <div className={styles.total}>Total: {
          currencyFormat(
            masterSaleGroup.rooms.reduce((a, b) => a += b.publicPriceTotal, 0) +
            masterSaleGroup.services.reduce((a, b) => a += b.publicPriceTotal, 0)
          )}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button className="btn btn-primary btn-icon"
          onClick={() => downloadPayments2()} >Descargar pagos</button>
        {
          masterSaleGroup.isOpen
            ? <button className="btn btn-danger btn-icon"
              onClick={() => openCloseMasterSale2(false)} >Cerrar Cuenta Maestra</button>
            : (
              role === "DIRECTOR"
                ? <button className="btn btn-danger btn-icon"
                  onClick={() => openCloseMasterSale2(true)} >Abrir Cuenta Maestra</button>
                : <></>
            )
        }
      </div>
    </>
  )
}
