import { useState } from "react";
import { Table } from "react-bootstrap";
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import styles from './Representative.module.css'
import { getMasterSaleByFile } from "../../../store/slices/files/thunks";
import { currencyFormat } from "../../../helpers/numericFields";

export const Representative = ({ file }) => {
  const dispatch = useDispatch();
  const { masterSale } = useSelector((state) => state.files);

  useEffect(() => {
    if (file.id) {
      dispatch(getMasterSaleByFile(file.id))
    }    
  }, [file.id])


  return (
    <>
            <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
        <div className="table-responsive">
          <Table className="table border text-nowrap mg-b-0">
            <thead>
              <tr>
                <th>Concepto</th>
                <th>Cantidad</th>
                <th>Descripcion</th>
                <th>Comision</th>
              </tr>
            </thead>
            <tbody>
              {
                masterSale.air.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.airline}</td>
                    <td>{currencyFormat(item.fee * (masterSale.fee / 100))}</td>
                  </tr>))
              }
              {
                masterSale.lodging.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.hotel} - {item.room}</td>
                    <td>{currencyFormat((item.rate - item.netPriceTotal) * (masterSale.fee / 100))}</td>
                  </tr>))
              }
              {
                masterSale.cruise.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.company} - {item.cabin}</td>
                    <td>{currencyFormat((item.rate - item.netPriceTotal) * (masterSale.fee / 100))}</td>
                  </tr>))
              }
              {
                masterSale.service.map(item => (
                  <tr key={item.id}>
                    <td>{item.concept}</td>
                    <td>1</td>
                    <td>{item.service}</td>
                    <td>{currencyFormat((item.rate - item.netPriceTotal) * (masterSale.fee / 100))}</td>
                  </tr>))
              }
            </tbody>
          </Table>
        </div>
        <div className={styles.total}>Total: {
          currencyFormat(
            masterSale.air.reduce((a, b) => a += (b.fee * (masterSale.fee / 100)), 0) +
            masterSale.lodging.reduce((a, b) => a += ((b.rate - b.netPriceTotal) * (masterSale.fee / 100)), 0) +
            masterSale.cruise.reduce((a, b) => a += ((b.rate - b.netPriceTotal) * (masterSale.fee / 100)), 0) +
            masterSale.service.reduce((a, b) => a += ((b.rate - b.netPriceTotal) * (masterSale.fee / 100)), 0) 
          )}
        </div>
      </div>
    </>
  )
}
