import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { useSelector, useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import { editGuestInChair } from "../../../store/slices/group/thunks";
import { MySelect } from "../../ui/controls/MySelect";

export const AddGuest = ({ code, isOpen, setIsOpen, chair }) => {
  const { guestList, weedingPassList } = useSelector((state) => state.group);
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Invitado A Mesa
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            guest: undefined,
            externalGuest: undefined,
          }}
          onSubmit={(({ guest, externalGuest
          }, { resetForm, setErrors }) => {

            if (!guest && !externalGuest) {
              return setErrors({ "guest": "Requerido" })
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(editGuestInChair(guest ? guest.value : null, externalGuest ? externalGuest.value : null,
              chair, code, success));
          })}
          validationSchema={
            Yup.object({
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Invitado:*' name="guest" options={[...guestList, ...weedingPassList]} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("guest", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Invitado Externo:*' name="externalGuest" options={weedingPassList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("externalGuest", e);
                            setFieldValue("guest", null);
                          }} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Agregar
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
