import { useQuery } from '../../hooks/useQuery';
import * as colors from '../../const/colors'
import { getFileById } from '../../store/slices/files/thunks';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAirlinesList, getCurrenciesList, getHotelsList, getWholesalersList, 
  getCategoriesList } from '../../store/slices/general/thunks';
import { Passengers } from './Passenger/Passengers';
import { Quotes } from './Quote/Quotes';
import { MasterSale } from './MasterSale/MasterSale';
import { resetMasterSale, setFile, setOptionSelected } from '../../store/slices/files/filesSlice';
import { useNavigate } from 'react-router-dom';
import { Representative } from './Representative/Representative';

export const FileDetailsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const id = (query.get("id")) ? query.get("id") : "";
  const { file, masterSale, optionSelected  } = useSelector((state) => state.files);

  useEffect(() => {
    if (id) {
      dispatch(getFileById(id))
    }
    return () => {
      dispatch(setFile({}));
    }
  }, [id])

  useEffect(() => {
    dispatch(getCurrenciesList());
  }, [])
  useEffect(() => {
    dispatch(getAirlinesList());
  }, [])
  useEffect(() => {
    dispatch(getWholesalersList());
  }, [])
  useEffect(() => {
    dispatch(getCategoriesList());
  }, [])
  useEffect(() => {
    dispatch(getHotelsList());
  }, [])
  useEffect(() => {
    return () => {
      dispatch(setOptionSelected("Quotes"))
      dispatch(resetMasterSale())
    }
  }, [])

  if(file && file.id == -1){    
    navigate("/panel/expedientes");
  }

  return (
    <div>
      <div style={{
        textAlign: "left",
        marginTop: 20,
        fontSize: 25,
        color: colors.title,        
      }}>{`Expediente #${id}`}</div>

      <div className="d-flex" style={{ marginTop: 20, marginBottom: 20 }} >

      <button className={`btn text-white ${optionSelected === "Quotes" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => dispatch(setOptionSelected("Quotes"))} >
          Cotizaciones
        </button>
        <button className={`btn text-white ${optionSelected === "Passengers" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => dispatch(setOptionSelected("Passengers"))} >
          Pasajeros
        </button>
        {
          masterSale.isOpen === 0
          && <button className={`btn text-white ${optionSelected === "MasterSale" ? "btn-secondary" : "btn-primary"}`}
            onClick={() => dispatch(setOptionSelected("MasterSale"))} >
            Venta
          </button>
        }
        <button className={`btn text-white ${optionSelected === "Agent" ? "btn-secondary" : "btn-primary"}`}
          onClick={() => dispatch(setOptionSelected("Agent"))} >
          Agente
        </button>
      </div>
      {optionSelected === "Quotes" && <Quotes file={file} />}
      {optionSelected === "Passengers" &&  <Passengers file={file} />}
      {optionSelected === "MasterSale" && <MasterSale file={file} />}
      {optionSelected === "Agent" && <Representative file={file} />}

    </div>
  )
}
