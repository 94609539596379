import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCourtshipLine } from "../../../store/slices/group/groupSlice";
import { getCourtshipLine } from "../../../store/slices/group/thunks";
import { AddItem } from "./AddItem";
import { CourtshipLineDetails } from "./CourtshipLineDetails";
import styles from './CourtshipLineScreen.module.css'

export const CourtshipLineScreen = ({ groupFile }) => {
  const { courtshipLine } = useSelector((state) => state.group);
  const [addItem, setAddItem] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCourtshipLine(groupFile.code));

    return () => {
      dispatch(setCourtshipLine([]));
    }
  }, [])

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1px solid #DCDCDC", background: "#F9F9F9", padding: 20 }}>
      <div className={`${styles.title}`}>Linea De Cortejo</div>

      <button className="btn btn-primary btn-info mt-2"
        onClick={() => setAddItem(true)}>Agregar Elemento</button>

      <CourtshipLineDetails courtshipLine={courtshipLine} code={groupFile.code} idGroupFile={groupFile.id} />
      <AddItem idGroupFile={groupFile.id} code={groupFile.code} isOpen={addItem} setIsOpen={setAddItem} />
    </div>
  )
}
