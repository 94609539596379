import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MySelect } from "../../ui/controls/MySelect";
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { MyDatePicker } from "../../ui/controls/MyDatePicker";
import { MyTextArea2 } from "../../ui/controls/MyTextArea2";
import { onlyIntegersDecimals } from "../../../helpers/numericFields";
import { useSelector, useDispatch } from 'react-redux';
import { getPaymentWayList } from "../../../store/slices/general/thunks";
import { MyTextLabel } from "../../ui/controls/MyTextLabel";
import { addPaymentWholesaler } from "../../../store/slices/files/thunks";

export const AddPaymentWholesaler = ({ masterSale, account, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { paymentMethodList, paymentWayList } = useSelector((state) => state.general);

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Agregar Pago A Mayorista
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            date: new Date(),
            amount: "",
            paymentMethod: undefined,
            paymentWay: undefined,
            notes: "",
          }}
          onSubmit={(({ date, amount, paymentMethod, paymentWay, notes
          }, { resetForm, setErrors }) => {

            if (!date) {
              return setErrors({ "date": "Requerido" })
            }
            if (!paymentMethod) {
              return setErrors({ "paymentMethod": "Requerido" })
            }
            if (!paymentWay) {
              return setErrors({ "paymentWay": "Requerido" })
            }

            let date2;
            if (date.year) {
              date2 = date.year + "-" + (date.monthIndex + 1) + "-" + date.day
            } else {
              date2 = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(addPaymentWholesaler(account.id, account.concept, date2, amount, paymentWay.value,
              notes, masterSale.idFile, success));


          })}
          validationSchema={
            Yup.object({
              amount: Yup.string()
                .required("Requerido"),
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel label='Cliente:' name='customer' value={masterSale.customer} mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel label='Descripcion:' name='description' value={account.description} mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextLabel label='Mayorista:' name='wholesaler' value={account.wholesaler} mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fecha de pago:*' name="date" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa"
                          onChange={e => {
                            setFieldValue("date", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Monto:*' name='amount' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("amount", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Metodo:*' name="paymentMethod" options={paymentMethodList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("paymentMethod", e);
                            dispatch(getPaymentWayList(e.value))
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MySelect label='Forma de pago:*' name="paymentWay" options={paymentWayList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("paymentWay", e);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextArea2 label='Notas:' name='notes' mode='horizontal' rows={4} />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>
      </Modal>
    </>
  )
}
