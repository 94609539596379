import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../../ui/controls/MyTextInput";
import { MyDatePicker } from "../../ui/controls/MyDatePicker";
import { onlyIntegersDecimals } from "../../../helpers/numericFields";
import { useDispatch } from 'react-redux';
import { addAgencyPaymentScheme } from "../../../store/slices/groupFiles/thunks";

export const AddAgencyPaymentScheme = ({ groupFile, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Programar Pago
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            date: new Date(),
            amount: "",
            topic: "",
          }}
          onSubmit={(({ date, amount, topic
          }, { resetForm, setErrors }) => {

            if (!date) {
              return setErrors({ "date": "Requerido" })
            }

            let date2;
            if (date.year) {
              date2 = date.year + "-" + (date.monthIndex + 1) + "-" + date.day
            } else {
              date2 = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
            }

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(addAgencyPaymentScheme(groupFile.id, topic, amount, date2, success));

          })}
          validationSchema={
            Yup.object({
              topic: Yup.string()
                .required("Requerido"),
              amount: Yup.string()
                .required("Requerido"),

            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Tipo de pago:' name='topic' mode='horizontal' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Monto:*' name='amount' mode='horizontal'
                          onChange={(e) => {
                            if (!onlyIntegersDecimals(e)) {
                              return
                            }
                            setFieldValue("amount", e.target.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" row mb-4">
                        <MyDatePicker
                          label='Fecha de pago:*' name="date" mode='horizontal'
                          className="form-control fc-datepicker"
                          singleSelect="true"
                          placeholder="dd/mm/aaaa"
                          onChange={e => {
                            setFieldValue("date", e);
                          }} />
                      </div>
                    </div>

                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Guardar Informacion
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
