import React, { useState } from 'react'
import { AddAdvancePayment } from './AddAdvancePayment';
import { AdvancePaymentDetails } from './AdvancePaymentDetails';

export const AdvancePayment = ({masterSale, code, groupCode}) => {
  const [addAdvancePayment, setAddAdvancePayment] = useState(false);

  return (
    <div style={{marginTop:20}}>
      <div style={{ fontSize: 20 }}>Pagos realizados</div>     
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button className="btn btn-primary btn-icon" style={{ marginTop: 10 }}
          onClick={() => setAddAdvancePayment(true)} >Mandar Pago Realizado</button>
      </div>

      <AdvancePaymentDetails masterSale={masterSale} code={code} />

      <AddAdvancePayment masterSale={masterSale} code={code} groupCode={groupCode} isOpen={addAdvancePayment} setIsOpen={setAddAdvancePayment} />
    </div>
  )
}
