import { currencyFormat } from '../../../helpers/numericFields';
import styles from './Header.module.css'


export const Header = ({ groupFile }) => {
  const { masterSaleLodging = { subTotal: 0 } } = groupFile;
  return (
    <div style={{ marginTop: 10, marginBottom: 20 }}>
      <div className={`${styles.title}`}>Fechas de grupo</div>

      <table>
        <tbody>
          <tr>
            <td>
              <div className={`${styles.subTitleBlock}`}>
                <label className={`${styles.subTitle}`}>Entrada:</label><label>{groupFile.startDate}</label>
              </div>
            </td>
            <td style={{paddingLeft:10}}>
              <div className={`${styles.subTitleBlock}`}>
                <label className={`${styles.subTitle}`}>Salida:</label><label>{groupFile.endDate}</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={`${styles.subTitleBlock}`}>
                <label className={`${styles.subTitle}`}>Estancia:</label>
                <label>{groupFile.nights} Noches</label>
                <label style={{ marginLeft: "10px" }}>{groupFile.rooms} Habitaciones</label>
                <label style={{ marginLeft: "10px" }}>{groupFile.passengers} Huespedes</label>
              </div>
            </td>
            <td style={{paddingLeft:10}}>
              <div className={`${styles.subTitleBlock}`}>
                <label className={`${styles.subTitle}`}>Cuartos Noche:</label><label>
                  {(groupFile.rooms && groupFile.nights) && groupFile.rooms * groupFile.nights}</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={`${styles.subTitleBlock}`}>
                <label className={`${styles.subTitle}`}>Habitacion Base:</label><label>{masterSaleLodging.room}</label>
              </div>
            </td>
            <td style={{paddingLeft:10}}>
              <div className={`${styles.subTitleBlock}`}>
                <label className={`${styles.subTitle}`}>Numero de habitaciones:</label><label>{masterSaleLodging.quantity}</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={`${styles.subTitleBlock}`}>
                <label className={`${styles.subTitle}`}>Tarifa:</label><label>{currencyFormat(masterSaleLodging.subTotal)}</label>
              </div>
            </td>
            <td style={{paddingLeft:10}}>
              <div className={`${styles.subTitleBlock}`}>
                <label className={`${styles.subTitle}`}>Tipo hospedaje:</label><label>{masterSaleLodging.lodgingType}</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={`${styles.subTitleBlock}`}>
                <label className={`${styles.subTitle}`}>Paquete de bodas:</label><label>{groupFile.guests + " / " + groupFile.peopleQuantity}</label>
              </div>
            </td>
            <td style={{paddingLeft:10}}>

            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className={`${styles.subTitleBlock}`}>
                <label className={`${styles.subTitle}`}>Notas:</label><label>{groupFile.notes}</label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
