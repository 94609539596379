import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { MySelect39 } from "../../ui/controls/MySelect39";
import { setExternalGuestHighlighted, setGuestHighlighted } from "../../../store/slices/group/groupSlice";

export const SearchGuest = ({ code, isOpen, setIsOpen, fullGuestList, fullWeedingPassList }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Buscar Invitado
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>
        <Formik
          initialValues={{
            guest: undefined,
            externalGuest: undefined,
          }}
          onSubmit={(({ guest, externalGuest
          }, { resetForm, setErrors }) => {

            if (!guest && !externalGuest ) {
              return setErrors({ "guest": "Requerido" })
            }

            setIsOpen(false);
            if(guest){
              dispatch(setGuestHighlighted(guest.value));
              dispatch(setExternalGuestHighlighted(0));
            } 
            if(externalGuest) {
              dispatch(setExternalGuestHighlighted(externalGuest.value));
              dispatch(setGuestHighlighted(0));
            }
          })}
          validationSchema={
            Yup.object({
            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-10 col-md-10">
                      <div className=" row">
                        <MySelect39 label='Invitado:' name="guest" options={fullGuestList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("guest", e);
                            setFieldValue("externalGuest", null);
                          }} />
                      </div>
                    </div>
                    <div className="col-lg-10 col-md-10">
                      <div className=" row">
                        <MySelect39 label='Invitado Externo:' name="externalGuest" options={fullWeedingPassList} mode='horizontal'
                          singleSelect="true"
                          onChange={e => {
                            setFieldValue("externalGuest", e);
                            setFieldValue("guest", null);
                          }} />
                      </div>
                    </div>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Buscar
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>
      </Modal>
    </>
  )
}
