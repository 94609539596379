import React, { useState } from 'react'
import { AddAirportHotel } from './AddAirportHotel';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AirportDetails } from './AirportDetails';
import { transportRequests } from '../../../store/slices/guest/thunks';
import { AddHotelAirport } from './AddHotelAirport';

export const Airport = ({ code }) => {
  const dispatch = useDispatch();
  const { transportRequests: transportRequests2 } = useSelector((state) => state.guest);
  const [addAirportHotel, setAddAirportHotel] = useState(false);
  const [addHotelAirport, setHotelAirport] = useState(false);

  useEffect(() => {
    dispatch(transportRequests(code))
  }, [])

  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ fontSize: 20 }}>Aeropuerto Hotel - Hotel Aeropuerto</div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button className="btn btn-primary btn-icon" style={{ marginTop: 10 }}
          onClick={() => setAddAirportHotel(true)} >Solicitar Aeropuerto-Hotel</button>
        <button className="btn btn-primary btn-icon" style={{ marginTop: 10 }}
          onClick={() => setHotelAirport(true)} >Solicitar Hotel-Aeropuerto</button>
      </div>

      <AirportDetails code={code} transportRequests={transportRequests2} />

      <AddAirportHotel code={code} isOpen={addAirportHotel} setIsOpen={setAddAirportHotel} />
      <AddHotelAirport code={code} isOpen={addHotelAirport} setIsOpen={setHotelAirport} />
    </div>
  )
}
