import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getPendingPayments } from '../../../store/slices/groupFiles/thunks';
import { AdvancePaymentDetails } from './AdvancePaymentDetails'

export const AdvancePayment = () => {
  const dispatch = useDispatch();
  const { pendingPayments } = useSelector((state) => state.groupFiles);
  useEffect(() => {
    dispatch(getPendingPayments())
  }, [])
  

  return (
    <div style={{marginTop:20}}>
      <div style={{ fontSize: 20 }}>Pagos pendientes por aprobar</div>
      <AdvancePaymentDetails pendingPayments={pendingPayments} />
    </div>
  )
}
