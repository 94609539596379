import { Button, Modal, Row } from "react-bootstrap";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { editRoomReserve } from "../../../../store/slices/individualFiles/thunks";
import { MyTextInput } from "../../../ui/controls/MyTextInput";

export const EditReserve = ({ room, individualFile, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        size="xl"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title >
            Editar Reserva En Habitacion
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Formik
          initialValues={{
            reserve: room.reserve,
          }}
          onSubmit={(({ reserve }, { resetForm, setErrors }) => {

            const success = () => {
              resetForm();
              setIsOpen(false)
            }

            dispatch(editRoomReserve(room.id, reserve, individualFile.id, success));
          })}
          validationSchema={
            Yup.object({

            })
          }
        >

          {
            ({ setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <Row>
                    <div className="col-lg-12 col-md-12">
                      <div className=" row mb-4">
                        <MyTextInput label='Reserva:' name='reserve' mode='horizontal' />
                      </div>
                    </div>
                  </Row>
                  <div style={{ fontSize: 13 }}>* Dato obligatorio</div>
                </Modal.Body>

                <Modal.Footer>
                  <div style={{ width: "100%" }}>
                    <Button variant="primary" type="submit" style={{ float: "right" }}>
                      Guardar Informacion
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>


      </Modal>
    </>
  )
}
