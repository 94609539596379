import { createSlice } from '@reduxjs/toolkit';

export const quotesSlice = createSlice({
  name: 'quotes',
  initialState: {
    quotesList: [],
    quote: {}
  },
  reducers: {
    setQuotesList: (state, action) => {
      state.quotesList = action.payload;
    },
    setQuote: (state, action) => {
      state.quote = action.payload;
    },
  }
});


// Action creators are generated for each case reducer function
export const { setQuotesList, setQuote } = quotesSlice.actions;