import React from 'react'
import {  useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPaymentMethodList } from '../../store/slices/general/thunks';
import { Details } from './Details';
import { Header } from './Header';

export const GuestScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { info } = useSelector((state) => state.guest);

  useEffect(() => {
    if (!info || !info.id) {
      navigate("/invitado")
    }
  }, [info]);


  useEffect(() => {
    dispatch(getPaymentMethodList())
  }, [])

  return (
    <div>      
      <Header info={info} />    
      <div >
        Nota: Si tienes alguna duda respecto de tus pagos, por favor comunicate al 614 192 2828 o 614 362 3709
      </div>
      
      <Details info={info} />
    </div>
  )
}
