import { AccountsPayableDetails } from './AccountsPayableDetails';

export const AccountsPayable = ({masterSale}) => {

  return (
    <div style={{marginTop:40}}>
      <div style={{ fontSize: 20}}>Cuentas por pagar a mayoristas</div>
      <AccountsPayableDetails masterSale={masterSale} />
    </div>
  )
}
