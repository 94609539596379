import React from 'react'
import { ChurchDetails } from './ChurchDetails'
import { getGuestTransportChurchList } from '../../../store/slices/group/thunks'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const Details = ({info}) => {
  const dispatch = useDispatch();
  const { transportChurch } = useSelector((state) => state.group);

  useEffect(() => {
    dispatch(getGuestTransportChurchList(info.code))
  }, [])

  return (
    <div key={info.id} style={{ marginTop: 20, marginBottom: 50, border: "1px solid #DCDCDC", background: "#F9F9F9", padding: 20 }}>
      <div style={{ fontSize: 22 }}>Transporte</div>      
      <ChurchDetails code={info.code} transportChurch={transportChurch} />
    </div>
  )
}
