
import { useSelector, useDispatch } from 'react-redux';
import { PeopleControl } from './PeopleControl';
import { RoomControl } from "./RoomControl";
import { roomingListDetails } from '../../../store/slices/groupFiles/thunks';

export const Control = () => {
  const dispatch = useDispatch();
  const { roomingList } = useSelector((state) => state.groupFiles);

  return (
    <>
      {
        roomingList.control.map(hotel => (
          <div key={hotel.idHotel}>
            <div style={{display:"flex", flexDirection:"row", justifyContent: "space-between", marginBottom: 20}}>
              <div style={{ fontSize: 20, marginBottom: 10 }}>Hotel: {hotel.hotel}</div>
              <button className={`btn text-white btn-primary`} 
                onClick={() => dispatch(roomingListDetails(hotel.idGroupFile, hotel.idHotel))} >Descargar Rooming List</button>
            </div>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <RoomControl hotel={hotel} />
              <PeopleControl hotel={hotel} />
            </ div>
          </div>

        ))
      }
    </>
  )
}
